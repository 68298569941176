
import React, { useEffect } from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function AjouterChantier() {
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
      nom_chantier: '',
        adresse: '',
        Lat: '',
        Date_debut:'',
        Date_fin:'',
        budjet:'' ,
        Nom_Projet:''      
      });
      const [projet , setProject] = useState([])
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
    const handleSubmit = (e) => {
        e.preventDefault();
        e.preventDefault();
        axios.post('https://serv.construction.yuliagroupe.com/api/chantiers', formValues)
        .then(response => {
          console.log('chantiers ajouté avec succès', response.data.data);
          navigate('/chantier');
          })
          .catch(error => {
            console.error('Erreur lors de l\'ajout de chantier', error);
          });
 
    }
  
  
 
    useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/projets")
      .then((res)=>{
        setProject(res.data.data)
      })
    },[])
 
 
 
 
    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Ajouter Chantier
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom_Chantier" name="nom_chantier" value={formValues.nom_chantier} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Adress" name="adresse" value={formValues.adresse} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Lat" name="Lat" value={formValues.Lat} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
    
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth  name="Date_debut" value={formValues.Date_debut} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth  name="Date_fin" value={formValues.Date_fin} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid>


                  <Grid item xs={6}>
                    <TextField fullWidth label="Budjet" name="budjet" value={formValues.Budjet} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}
                     
                     />
                      
                  </Grid>
                  <Grid item xs={6}>
        <TextField
          id="outlined-select-currency-native"
          select
          label='nom_projet'
          name='Nom_Projet'
          value={formValues.Nom_Projet}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'180vh'
            },
          }} 
        >
          <option value="">Sélectionnez un projet</option>
          {projet.map((pr)=>(
            <option key={pr.id_projet} value={pr.id_projet}>{pr.nom_projet}</option>
          ))}
          
        </TextField>
      </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
