import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Appbar from './Appbar';
import { TextField, Button, Grid, Typography, Paper, Box } from '@mui/material';

function AjouterSoustypeConstrc() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    ID_SousTypeConstr: '',
    Nom_SousTypeConstr: '',
    Descripsion: '',
    Nom_typeconst: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://serv.construction.yuliagroupe.com/api/sous-types-construction', formValues)
      .then(response => {
        console.log('Sous type construction ajouté avec succès', response.data.data);
        navigate('/SousTypeContsrc');
      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout de sous type construction', error);
      });
  };

  const [typesConstructions, setTypesConstructions] = useState([]);

  useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/typesconstruction")
      .then((res) => {
        setTypesConstructions(res.data.data);
      });
  }, []);

  return (
    <Appbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5', marginTop: '80px' }}>
          <Typography variant="h5" align="center" sx={{ color: '#001f3f', fontWeight: 600 }}>
            Sous Type Construction
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              <Grid item xs={6}>
                <TextField fullWidth label="ID" name="id_st_constr" value={formValues.id_st_constr} onChange={handleChange} InputProps={{
                  style: {
                    borderRadius: "15px",
                    backgroundColor: 'rgba(255, 255, 255, 95%)',
                  },
                }} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Nom" name="nom_st_constr" value={formValues.nom_st_constr} onChange={handleChange} InputProps={{
                  style: {
                    borderRadius: "15px",
                    backgroundColor: 'rgba(255, 255, 255, 95%)',
                  },
                }} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Descripsion" name="Descripsion" value={formValues.Descripsion} onChange={handleChange} InputProps={{
                  style: {
                    borderRadius: "15px",
                    backgroundColor: 'rgba(255, 255, 255, 95%)',
                  },
                }} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label='Type Construction'
                  onChange={handleChange}
                  name="Nom_typeconst"
                  value={formValues.Nom_typeconst}
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                      backgroundColor: 'rgba(255, 255, 255, 95%)',
                      width: '350px'
                    },
                  }}
                >
                  <option value="">Sélectionnez un type de construction</option>
          {typesConstructions.map((type)=>(
            <option key={type.id_typeconst} value={type.id_typeconst}>{type.Nom_typeconst}</option>
          ))}
                </TextField>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400',
            },}}>
                        Ajouter
                      </Button>
                    </Box>
          </form>
        </Paper>
      </Box>
    </Appbar>
  );
}

export default AjouterSoustypeConstrc;