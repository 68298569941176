import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function AjouterProjet() {
    const [formValues, setFormValues] = useState({
        ID_Projet: '',
        nom_projet: '',
        date_debut:'',
        date_fin: '',
        nom_tache:'',
        id_entreprise:1
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 const navigate=useNavigate()
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/projets', formValues)
            .then(response => {
              console.log('projets ajouté avec succès', response.data.data);
              navigate('/projet');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de projet', error);
              });
          };

      //style
        
      const getInputProps = () => ({
        style: {
          borderRadius: "5px",
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          color: 'black', // Couleur du texte en noir
          
        },
      });
    
      const getSxProps = () => ({
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ccc', // Couleur de la bordure par défaut
          },
          '&:hover fieldset': {
            borderColor: '#bbb', // Couleur de la bordure au survol
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Couleur de la bordure au focus
          },
        },
      });
      
      const [taches , setTaches] = useState([])
      useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/taches")
      .then((res)=>{
        console.log(res.data.data)

        setTaches(res.data.data)
      })
      },[])




    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Ajouter Projet
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={6}>
                    <TextField fullWidth label="ID_projet" name="stage" value={formValues.ID_Projet} onChange={handleChange}          InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}  />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom_projet" name="nom_projet" value={formValues.nom_projet} onChange={handleChange}          InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}  />
                  </Grid>
                
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth label="date_debut" name="date_debut" value={formValues.date_debut} onChange={handleChange}          InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}  />
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth label="date_fin" name="date_fin" value={formValues.date_fin} onChange={handleChange}           InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}  
                     
                      />
                      
                  </Grid>
                  <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='tache'
          onChange={handleChange}
          name='nom_tache'
   value={formValues.nom_tache}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }} 
        >
 < option value="">Sélectionnez un projet</option>
          {taches.map((tache)=>(
            <option key={tache.id_tache} value={tache.id_tache}>{tache.nom_tache}</option>
          ))}
         
        </TextField>
                      </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
