import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import {  IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export default function MaterielInterne() {
    const [openCon, setOpenCon] = React.useState(false);
    const navigate = useNavigate()
    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
  
  
    const[materielinters,setMaterielinters]=React.useState([])


    const handleDelete = (id_materiel) => {
        axios.delete(`https://serv.construction.yuliagroupe.com/api/materiels/${id_materiel}`)
          .then(response => {
            console.log('TypeRisque supprimé avec succès');
            
            const updatedUsers = materielinters.filter(materielinter => materielinter.id_materiel !== id_materiel);
            setMaterielinters(updatedUsers);
            handleCloseCon();
          })
          .catch(error => {
            console.error('Erreur lors de la suppression de l\'types-risque', error);
            });
          }; 





      useEffect(() => {
        const isloged = JSON.parse(localStorage.getItem("isloged"))
        if(!isloged){
          navigate("/")
        }else{
        axios.get("https://serv.construction.yuliagroupe.com/api/materiels").then((res) => {
        console.log(res.data.materiels)  
        setMaterielinters(res.data.materiels)
      });
    }
  
  },[]);
  
  
  
  
  
    return (
    <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
<Grid container spacing={2} alignItems="center">
<Link to="/AjouterMaterielInterne" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
</Grid>
</Box>

<TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
            <TableRow>
                <TableCell align="center">nom_materielInterne</TableCell>
                <TableCell align="center">Quantitée_MaterielInterne</TableCell>
                <TableCell align="center">Action</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
                    {materielinters.map((material)=>(
          <TableRow key={material.id_materiel}>
          <TableCell align="center">{material.Nom_materiel}</TableCell>
          <TableCell align="center">{material.Quantité_materiel}</TableCell>

    

          <TableCell align="center">
            <Link to={"/ModifierMaterielInterne/"+material.id_materiel}>
              <IconButton>
                <EditIcon sx={{ color: '#d35400' }} />
              </IconButton>
            </Link>
            <React.Fragment>
              <IconButton variant="outlined" onClick={handleClickOpenCon}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
              <Dialog
                open={openCon}
                onClose={handleCloseCon}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent sx={{ backgroundColor: '#d35400' }}>
                  <DialogContentText id="alert-dialog-description">
                    Vous avez sure de supprimer 
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#d35400' }}>
                  <Button onClick={handleCloseCon}>Non</Button>
                  <Button onClick={()=>handleDelete(material.id_materiel)}>Oui</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </TableCell>
        </TableRow>
        ))}
        

    </TableBody>
    </Table>
</TableContainer>
</Appbar>

  )
}
