import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, FeatureGroup, Popup,Rectangle, CircleMarker, Circle,Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { Polyline } from 'react-leaflet';
import { Box, Container ,TextField,Grid, Typography} from '@mui/material';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import axios from 'axios';
import Appbar from './Appbar';

function Maps() {
  const [formValues, setFormValues] = useState({
      id_chantier: '',
   
    });
  const center = [34.2208, -4.0103];
  const [mapPts, setMapPts] = useState([]);
  const [points, setPoints] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [getdata, setGetdata] = useState([]);

  const _onCreate = (e) => {
    const { layerType, layer } = e;

    if (layerType === 'polygon' || layerType === 'rectangle' || layerType === 'polyline') {
         const { _leaflet_id } = layer;

      const latLngs = layer.getLatLngs()[0];
      const popupContent = `This is a ${layerType} with ID ${_leaflet_id}`;
      const popup = L.popup().setLatLng(latLngs[0]).setContent(popupContent);
      layer.bindPopup(popup).openPopup();
      setMapPts((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: latLngs, type: layerType }
      ]);
    } else if (layerType === 'circle') {
      const latLng = layer.getLatLng();
      const radius = layer.getRadius();
      const popupContent = `This is a ${layerType} with radius ${radius} meters`;
      const popup = L.popup().setLatLng(latLng).setContent(popupContent);
      layer.bindPopup(popup).openPopup();
      setMapPts((layers) => [
        ...layers,
        { center: latLng, radius, type: layerType }
      ]);
    } else if (layerType === 'circlemarker') {
      const latLng = layer.getLatLng();
      const popupContent = `This is a ${layerType}`;
      const popup = L.popup().setLatLng(latLng).setContent(popupContent);
      layer.bindPopup(popup).openPopup();
      setMapPts((layers) => [
        ...layers,
        { latlng: latLng, type: layerType }
      ]);
    }
  };

  const _onEdited = (e) => {
    console.log("changing")
    const { layers: _layers } = e;
    _layers.eachLayer((layer) => {
      const { _leaflet_id, editing } = layer;
      
      if (_leaflet_id && editing) {
        console.log(mapPts)
        const existingPolygonIndex = mapPts.findIndex((p) => p.id === _leaflet_id);
        console.log(existingPolygonIndex)
        if (existingPolygonIndex !== -1) {
          // Update existing polygon in mapPts
          console.log("aaaa")
          setMapPts((layers) => {
          
            return layers.map((ele) =>
              ele.id === _leaflet_id ? { ...ele, latlngs: editing.latlngs[0], isNew: false } : ele
            );
          });
          
          // Send PUT request to update polygon on backend
          updatePolygonOnBackend(mapPts[existingPolygonIndex]);
        }
      }
    });
  };

  const updatePolygonOnBackend = (polygonData) => {
    console.log(polygonData)
    const { id, latlngs, type, chantier } = polygonData; // Assuming backend uses these fields
    axios.put(`https://serv.construction.yuliagroupe.com/api/coordonees/${id}`, {
      latlngs: JSON.stringify(latlngs),
      type,
      chantier: formValues.chantier, // Include selected chantier
    })
      .then((response) => {
        console.log('Polygon updated successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error updating polygon:', error);
      });
  };

  const _onDeleted = (e) => {
    const { layers: _layers } = e;
    _layers.eachLayer((layer) => {
      const { _leaflet_id } = layer;
      if (_leaflet_id) {
        setMapPts((layers) => layers.filter((l) => l.id !== _leaflet_id));
      }
    });
  };

  const renderMapLayers = (data) => {
    return data.map(item => {
      const { type, latlngs } = item;
      switch (type) {
        case 'polyline':
          console.log("Polyline latlngs:", latlngs.latlngs.lat) ;

          return latlngs.latlngs && Array.isArray(latlngs.latlngs) ? (
            <Polyline key={item.id_coordonees} positions={latlngs.latlngs.map(coord => [coord.lat, coord.lng])} >
              </Polyline>
          ) : null;
        case 'rectangle':
          
          return latlngs.latlngs && Array.isArray(latlngs.latlngs) ? (
            <Rectangle key={item.id_coordonees} bounds={latlngs.latlngs.map(coord => [coord.lat, coord.lng])} >
       <Popup>
                  <Typography>Chantier: {item.chantier ? item.chantier.nom_chantier : null}</Typography>
                  <Typography>Date Debut:{item.chantier ? item.chantier.Date_debut : null}</Typography>
                  <Typography>Date Fin: {item.chantier ? item.chantier.Date_fin : null}</Typography>
                  <Typography>Budjet:{item.chantier ? item.chantier.budjet : null}</Typography>
              </Popup>
       </Rectangle>
       
         ) : null;
          
        case 'circlemarker':
          return latlngs.latlng ? (
            <CircleMarker key={item.id_coordonees} center={[latlngs.latlng.lat, latlngs.latlng.lng]} >
           <Popup>
                  <Typography>Chantier: {item.chantier ? item.chantier.nom_chantier : null}</Typography>
                  <Typography>Date Debut:{item.chantier ? item.chantier.Date_debut : null}</Typography>
                  <Typography>Date Fin: {item.chantier ? item.chantier.Date_fin : null}</Typography>
                  <Typography>Budjet:{item.chantier ? item.chantier.budjet : null}</Typography>
              </Popup>
            </CircleMarker>
          ) : null;
        case 'circle':
          return latlngs.center && !isNaN(latlngs.radius) ? (
            <Circle key={item.id_coordonees} center={[latlngs.center.lat, latlngs.center.lng]} radius={latlngs.radius} >
  <Popup>
                  <Typography>Chantier: {item.chantier ? item.chantier.nom_chantier : null}</Typography>
                  <Typography>Date Debut:{item.chantier ? item.chantier.Date_debut : null}</Typography>
                  <Typography>Date Fin: {item.chantier ? item.chantier.Date_fin : null}</Typography>
                  <Typography>Budjet:{item.chantier ? item.chantier.budjet : null}</Typography>
              </Popup>


            </Circle>
          ) : null;
          case 'polygon':
            return latlngs.latlngs && Array.isArray(latlngs.latlngs) ? (
              <Polygon key={item.id_coordonees} positions ={latlngs.latlngs.map(coord => [coord.lat, coord.lng])} >
             <Popup>
                  <Typography>Chantier: {item.chantier ? item.chantier.nom_chantier : null}</Typography>
                  <Typography>Date Debut:{item.chantier ? item.chantier.Date_debut : null}</Typography>
                  <Typography>Date Fin: {item.chantier ? item.chantier.Date_fin : null}</Typography>
                  <Typography>Budjet:{item.chantier ? item.chantier.budjet : null}</Typography>
              </Popup>
            </Polygon>
             
            ) : null;
          default:
          return null;
      }
    });
  };
  

  const parseLatLngs = (data) => {
    return data.map(item => {
      const latlngs = JSON.parse(item.latlngs);
      // Ensure radius is correctly parsed for circles
      if (latlngs.type === 'circle' && latlngs.radius) {
        latlngs.radius = parseFloat(latlngs.radius);
      }
      return { ...item, latlngs };
    });
  };

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setPoints([...points, { lat, lng }]);
    setCoordinates((prevCoords) => [...prevCoords, { lat, lng }]);
  };

  useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/coordonees")
      .then((res) => {
        console.log( 'hahiya data ',res.data);
        setGetdata(parseLatLngs(res.data.data));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const[chantiers,setChantiers]=useState([])
  useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/chantiers").then((res) => {
      setChantiers(res.data.data)
    });
    },[]);






    const sendMapPtsToServer = (data) => {
      console.log('Formatted hahchno fiha:', data);
      data.forEach((dat) => {
        axios.post('https://serv.construction.yuliagroupe.com/api/coordonees', { 
          latlngs: JSON.stringify(dat), 
          type: dat.type,
          chantier: formValues.chantier // Include the selected chantier value here
        })
        .then(response => {
          console.log('Data sent successfully:', response.data);
          setMapPts([]);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
      });
    };
    
    useEffect(() => {
      if (mapPts.length > 0) {
        sendMapPtsToServer(mapPts);
      }
    }, [mapPts]);

  return (
    <Appbar>
    <Container maxWidth="md" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: "100px" }}>
      <Box sx={{ width: '100%', marginBottom: '20px' }}>
        <TextField
          id="outlined-select-currency-native"
          select
          name='chantier'
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width: '350px'
            },
          }}
        >
          <option value="">Sélectionnez un chantier</option>
          {chantiers.map((chantier) => (
            <option key={chantier.id_chantier} value={chantier.id_chantier}>{chantier.nom_chantier}</option>
          ))}
        </TextField>
      </Box>
      <Box sx={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MapContainer center={center} zoom={13} style={{ height: '100%', width: '100%' }} onClick={handleMapClick}>
          <FeatureGroup>
            <EditControl
              position='topright'
              onCreated={_onCreate}
              onDeleted={_onDeleted}
              onEdited={_onEdited}
            />
            {renderMapLayers(getdata)}
          </FeatureGroup>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </MapContainer>
      </Box>
    </Container>
    <pre className='text8left'>{JSON.stringify(mapPts, 0, 2)}</pre>
    {console.log("aa"+mapPts)}
    {console.log(coordinates)}
  </Appbar>

  );
}

export default Maps;
