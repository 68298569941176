import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import DialogContentText from '@mui/material/DialogContentText';
import { useState,useEffect } from 'react';
import axios from 'axios';
function Tache() {
  const navigate = useNavigate()
  const [taches, setTaches] = useState([]);
  const [formData, setFormData] = useState({
    id_tache:'',
    nom_tache: '',
    description_tache: '',
    date_debut:'',
    date_fin: '',
    durée_tache:''
  });
 
 
    const [openCon, setOpenCon] = React.useState(false);

    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
    
    useEffect(() => {
      const isloged = JSON.parse(localStorage.getItem("isloged"))
      if(!isloged){
        navigate("/")
      }else{
      axios.get("https://serv.construction.yuliagroupe.com/api/taches").then((res) => {
        console.log(res.data.data);
        setTaches(res.data.data)
      });
    }
    
    },[]);
      
    
     
    const handleDelete = (id_tache) => {
      axios.delete(`https://serv.construction.yuliagroupe.com/api/taches/${id_tache}`)
        .then(response => {
          console.log('tache supprimé avec succès');
          // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
          const updatedUsers = taches.filter(tache => tache.id_tache !== id_tache);
          setTaches(updatedUsers);
          handleCloseCon();
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de la tache', error);
        });
    };
      
      
 
    return (
    <Appbar>
  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                    <Link to="/AjouterTache" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{
              mb: 2,
              bgcolor: '#d35400',
              borderRadius: '9px',
              fontFamily: 'Roboto',
              '&:hover': { bgcolor: '#d35400' },
              color: 'white',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              alignSelf: 'flex-end',
              marginLeft: '8px',
            }}>
              <AddCircleIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
              Ajouter tache
            </Button>
          </Link>
                    </Grid>
                   
                </Grid>
            </Box>

            <TableContainer style={{ width: '1000px', marginLeft: '50px', borderRadius: '9px', border: '1px solid ', marginTop: '20px' }}>
            <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 600 }}>Nom_Tache</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 600 }}>Description_Tache</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 600 }}>Date_Debut</TableCell>
                    <TableCell align="center"sx={{ fontWeight: 600 }}>Date_Fin</TableCell>
                    <TableCell align="center"sx={{ fontWeight: 600 }}>Durée</TableCell>
                    <TableCell align="center" sx={{color:'#001F3F', fontWeight: 600}}>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {taches.map((tache) => (
              <TableRow key={tache.id}>
                <TableCell align="center">{tache.nom_tache}</TableCell>
                <TableCell align="center">{tache.description_tache}</TableCell>
                <TableCell align="center">{tache.date_debut}</TableCell>
                <TableCell align="center">{tache.date_fin}</TableCell>
                <TableCell align="center">{tache.durée_tache}</TableCell>

                <TableCell align="center">
                  <Link to={'/ModifierTache/'+tache.id_tache}>
                  {/* onClick={() => handleEdite(id_utilisateur)} */}
                    <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                  </Link>
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(tache.id_tache)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </TableContainer>
</Appbar>
)
  
}

export default Tache
