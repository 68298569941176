import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';

export default function AjouterPointage() {
 
 
    const [formValues, setFormValues] = useState({
        nom_Chantier: '',
        Date:''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
    const handleSubmit = (e) => {
        e.preventDefault();
 
    }
 
 
 
 
 
 
 
 
    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Ajout Chantier
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  
                  
                  <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='nom_chantier'
value={formValues.nom_Chantier}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'500px'
            },
          }} 
        >
  <option value="USD">  </option>
  <option value="GBP">Nom_Chantier 2</option>
  <option value="JPY">nom_chantier 3</option>
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                    <TextField fullWidth type='date' label="Date" name="nom" value={formValues.Date} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
