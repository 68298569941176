
import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
export default function AjouterMatierStock() {
    const [formValues, setFormValues] = useState({
        Nom_MatiPre: '',
        Nom_Stock: '',
        Date_Dentrer :'',
        Date_Sortie:'',
        Quantite:''
      });
  
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };
    



const handleSubmit = (e) => {
    e.preventDefault();

}
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Matiere premier_Stock
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='nom_MatierePremier'
value={formValues.Nom_MatiPre}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
  <option value="USD">  </option>
  <option value="GBP">Nom_matiere 2</option>
  <option value="JPY">nom_matiere 3</option>
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
value={formValues.Nom_Stock}
label='nom_stock'
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }}   >
          <option value="EUR"> </option>
  <option value="GBP">stock 2</option>
  <option value="JPY">stock 3</option>
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                    <TextField label='Quantite' type='number' fullWidth  name="quantite" value={formValues.Quantite} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid>
                      <Grid item xs={6}>
                    <TextField type='date' fullWidth  name="date_entrer" value={formValues.Date_Dentrer} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth  name="date_sortie" value={formValues.Date_Sortie} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid>


                 
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Confirmer
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
 
                  </Appbar>
  )
}
