import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function AjouterConstructionStage() {
    const [formValues, setFormValues] = useState({
      id_Stage: '',
      Stage_Name: '',
      Description: '',
      Couleur: '',
      Date_debut:'',
      Date_fin: ''
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
  
  
 
    const navigate = useNavigate();
 
    const handleSubmit = (e) => {
      e.preventDefault();
      axios.post('https://serv.construction.yuliagroupe.com/api/stages', formValues)
      .then(response => {
        console.log('constructio_stage ajouté avec succès', response.data.data);
        navigate('/Construction_Stage');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout de TypeRisque', error);
        });
          };
 
 
 
 
 
 
    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Ajouter Constructer_Stage
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={6}>
                    <TextField fullWidth label="id_Stage" name="id_Stage" value={formValues.id_Stage} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Stage_Name" name="Stage_Name" value={formValues.Stage_Name} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Description" name="Description" value={formValues.Description} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Couleur" name="Couleur" value={formValues.Couleur} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
    
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth label="Date_debut" name="Date_debut" value={formValues.Date_debut} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth label="Date_fin" name="Date_fin"  value={formValues.Date_fin} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}
                     
                      />
                      
                  </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
