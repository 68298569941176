import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function ReportingEtAnaylyse() {
    const styleFont =  {fontWeight: 600 }

  return (
    <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <IconButton>
                    <AddCircleIcon sx={{color:'#d35400'}}/>
                </IconButton>
            </Grid>
            <Grid item>
                <Typography variant="h6" sx={{color:'#001f3f'}}>Ajouter un Analyse</Typography>
            </Grid>
        </Grid>
    </Box>

    <TableContainer  style={{width:'1070px',marginLeft:'50px'}} >
        <Table sx={{ minWidth: 650, border: '0.1px solid #ddd'}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" sx={styleFont }>ID_RaportAnalystique</TableCell>
                    <TableCell align="center" sx={styleFont } >Fichier_RaportAnalystique</TableCell>
                    <TableCell align="center"sx={styleFont }>Rubrique_RaportAnalystique</TableCell>
                    <TableCell align="center" sx={styleFont }>Date_RaportAnalystique</TableCell>
                    <TableCell align="center"  sx={styleFont }>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align='center' >teste</TableCell>
                    <TableCell align='center'>teste</TableCell>
                    <TableCell align='center'>teste</TableCell>
                    <TableCell align='center'>teste</TableCell>
                    <TableCell align="center">
                        <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                        <IconButton><DeleteIcon sx={{color:'red'}}/></IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
</Appbar>
  )
}
