import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function AjouterRole() {
  
    const [formValues, setFormValues] = useState({
        nom_role: '',
       });
       const navigate = useNavigate();  

        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
 
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/roles', formValues)
            .then(response => {
              console.log('Roles ajouté avec succès', response.data.roles);
              navigate('/role');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de l\'utilisateur', error);
              });
          };
 
         
          const getInputProps = () => ({
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              color: 'black', 
              width:'1000px',
              display:'flex',
              // Couleur du texte en noir
              
            },
          });
        
          const getSxProps = () => ({
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc', // Couleur de la bordure par défaut
              },
              '&:hover fieldset': {
                borderColor: '#bbb', // Couleur de la bordure au survol
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Couleur de la bordure au focus
              },
            },
          });
          
  
  
  
  
  
  return (
<Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Role
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                 
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom_Role" name="nom_role" value={formValues.nom_role} onChange={handleChange}      InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' ,
              // Couleur du texte du label en noir
            }
          }}   />
                  </Grid>
                 
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
