import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import logo from './img/logo.png';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SettingsIcon from '@mui/icons-material/Settings';
import { Menu, MenuItem } from '@mui/material';
import { json, useNavigate } from 'react-router-dom';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#C5C5C5',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    backgroundColor: '#C5C5C5',
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function Appbar(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [userdata, setuserdata] = useState('');

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem('userdata'));
    console.log(data)
    setuserdata(data);
  }, []);

  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  function getIconForIndex(index) {
    switch (index) {
      case 0:
        return <PeopleIcon />;
      case 1:
        return <EngineeringIcon />;
      case 2:
        return <DescriptionIcon />;
      case 3:
        return <TaskAltIcon />;
      case 4:
        return <AccessTimeIcon />;
      case 5:
        return <ContactEmergencyIcon />;
      case 6:
        return <TimelineIcon />;
      case 7:
        return <LocalShippingIcon />;
      case 8:
        return <GppMaybeIcon />;
      case 9:
        return <TimelapseIcon />;
      default:
        return <CategoryIcon />;
    }
  }

  const handleProjectClick = () => {
    navigate('/projet');
  };
  const handleDashboardClick = () => {
    navigate('*');
  };
  const handleEntpsClick = () => {
    navigate('/entreprise');
  };
  const handleMapClick = () => {
    navigate('/Map');
  };

  const [anchorEld, setAnchorEld] = useState(null);

  const handleClickd = (event) => {
    setAnchorEld(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEld(null);
  };

  const handleLogout = () => {
    localStorage.setItem('isloged', false);
    localStorage.removeItem('userdata');
    navigate('/');
  };

  const user = JSON.parse(localStorage.getItem('userdata'));
  const nom = user.nom_utilisateur;
  const prenom = user.prenom_utilisateur;

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open} sx={{ backgroundColor: '#C5C5C5' }}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: '#001f3f',
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Avatar
              alt="Remy Sharp"
              src={logo}
              style={{ width: '120px', height: '50px', display: 'flex', alignItems: 'center', marginLeft: '-50px', marginTop: '10px' }}
            />

            <Stack direction="row" spacing={2} justifyContent="center">
              {userdata.id_role === '1' ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 85, backgroundColor: '#d35400' }}
                  onClick={handleDashboardClick}
                >
                  Dashboard
                </Button>
              ) : null}
              {userdata.id_role === '1' ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 85, backgroundColor: '#d35400' }}
                  onClick={handleProjectClick}
                >
                  Projects
                </Button>
              ) : null}
              {userdata.id_role === '1' ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 85, backgroundColor: '#d35400' }}
                  onClick={handleEntpsClick}
                >
                  Entreprise
                </Button>
              ) : null}
              {userdata.id_role === '1' ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 85, backgroundColor: '#d35400' }}
                  onClick={handleMapClick}
                >
                  Map
                </Button>
              ) : null}
            </Stack>
            {userdata.id_role === '1' ? (
              <Toolbar>
                <Menu id="menu" anchorEl={anchorEl2} open={Boolean(anchorEl2)} onClose={handleClose2}>
                  <MenuItem onClick={() => { navigate('/materielInterne'); handleClose2(); }}>materialInterne</MenuItem>
                  <MenuItem onClick={() => { navigate('/materiel'); handleClose2(); }}>materialFournisseur</MenuItem>
                  <MenuItem onClick={() => { navigate('/matierePremier'); handleClose2(); }}>MatierePremier</MenuItem>
                </Menu>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 85, backgroundColor: '#d35400' }}
                  onClick={handleClick2}
                >
                  materiel
                </Button>
              </Toolbar>
            ) : null}

            {userdata.id_role === '1' ? (
              <Toolbar sx={{ marginLeft: '350px' }}>
                <IconButton
                  edge="end"
                  color="#d35400"
                  aria-label="settings"
                  aria-controls="settings-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <SettingsIcon sx={{ color: '#d35400' }} />
                </IconButton>
                <Menu id="settings-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={() => { navigate('/TypeConstruction'); handleClose(); }}>Type Construction</MenuItem>
                  <MenuItem onClick={() => { navigate('/TypePrime'); handleClose(); }}>Type Prime</MenuItem>
                  <MenuItem onClick={() => { navigate('/TypeRisque'); handleClose(); }}>Type Risque</MenuItem>
                  <MenuItem onClick={() => { navigate('/SousTypeContsrc'); handleClose(); }}> SousType Construction </MenuItem>
                  <MenuItem onClick={() => { navigate('/Construction_Stage'); handleClose(); }}> Construction_Stage </MenuItem>
                  <MenuItem onClick={() => { navigate('/Services'); handleClose(); }}> Services </MenuItem>
                  <MenuItem onClick={() => { navigate('/Département'); handleClose(); }}> Département </MenuItem>
                  <MenuItem onClick={() => { navigate('/previlage'); handleClose(); }}> previlage </MenuItem>
                </Menu>
              </Toolbar>
            ) : null}

            <Avatar
              {...stringAvatar(`${nom} ${prenom}`)}
              sx={{ marginLeft: '80px',backgroundColor:'#d35200'}}
              onClick={handleClickd}
            />
            <Menu anchorEl={anchorEld} open={Boolean(anchorEld)} onClose={handleClosed}>
              <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer sx={{ backgroundColor: '#d35400' }} variant="permanent" open={open}>
          <DrawerHeader sx={{ backgroundColor: '#C5C5C5' }}>
            <IconButton onClick={handleDrawerClose} sx={{ color: '#001f3f' }}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          {userdata.id_role === '1' ? (
            <List sx={{ backgroundColor: '#C5C5C5' }}>
              {['utilisateur', 'employes', 'Role', 'tache', 'Pointage', 'Chantier', 'Timeline', 'fournisseur', 'risque', 'stock', 'produits'].map((text, index) => (
                <ListItem button key={text} onClick={() => handleNavigation('/' + text.toLowerCase())}>
                  <ListItemIcon>{getIconForIndex(index)}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          ) : (
            <List sx={{ backgroundColor: '#C5C5C5' }}>
              {['tache', 'Chantier', 'risque', 'produits'].map((text, index) => (
                <ListItem button key={text} onClick={() => handleNavigation('/' + text.toLowerCase())}>
                  <ListItemIcon>{getIconForIndex(index)}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          )}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <main>
            {props.children}
          </main>
        </Box>
      </Box>
    </Box>
  );
}

export default Appbar;
