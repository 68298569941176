import React from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';

export default function ModifierRisque() {
    const [formValues, setFormValues] = useState({
      id_risque: '',
      nom_risque: ''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
          const navigate = useNavigate();
          const {id} = useParams()
          useEffect(()=>{
            handleEdit()
           },[])
           const handleEdit = (id_risque) => {
            axios.get(`https://serv.construction.yuliagroupe.com/api/risques/${id}`)
              .then(response => {
                console.log(response.data.data)
                const userData = response.data.data; 
                setFormValues({
                  id_risque: userData.id_risque,
                  nom_risque: userData.nom_risque,
                
                });
              })
              .catch(error => {
                console.error('Erreur lors de la récupération des données de risques', error);
              });
          };          
 
 
          const handleEditSubmit = (e) => {
            e.preventDefault();
            axios.put(`https://serv.construction.yuliagroupe.com/api/risques/${id}`,formValues)
              .then(response => {
                console.log('risques modifié avec succès');
                navigate('/risque'); // Utilisez history.push au lieu de navigate
              })
              .catch(error => {
                console.error('Erreur lors de la modification de risque', error);
              });
          };
 
  
  
  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Modifier Risque
                  </Typography>
                  <form onSubmit ={handleEditSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_risque" value={formValues.id_risque} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom_Risque" name="nom_risque" value={formValues.nom_risque} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      </Grid>
                    
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Modifier
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
