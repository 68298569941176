import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';
export default function Construction_stage() {
    const styleFont =  {fontWeight: 600 }
    const[constructionstages,setConstructionstages]=useState([])

    const navigate = useNavigate()
    const [openCon, setOpenCon] = React.useState(false);

    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
    useEffect(() => {
      const isloged = JSON.parse(localStorage.getItem("isloged"))
      if(!isloged){
        navigate("/")
      }else{
        axios.get("https://serv.construction.yuliagroupe.com/api/stages").then((res) => {
        console.log(res.data)  
        setConstructionstages(res.data.data)
      });
    }
  
  },[]);

  

        const handleDelete = (id_Stage) => {
          axios.delete(`https://serv.construction.yuliagroupe.com/api/stages/${id_Stage}`)
            .then(response => {
              console.log('constructionstages supprimé avec succès');
              
              const updatedUsers = constructionstages.filter(Constructionstages => Constructionstages.id_Stage !== id_Stage);
              setConstructionstages(updatedUsers);
              handleCloseCon();
            })
            .catch(error => {
              console.error('Erreur lors de la suppression de l\'constructionstages', error);
            });
        };









    
  return (
    <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
        <Grid container spacing={2} alignItems="center">
        <Link to="/AjoutConstructionStage" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
        </Grid>
    </Box>

    <TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                <TableRow>
                    <TableCell align="center" sx={styleFont } >Stage_Name</TableCell>
                    <TableCell align="center"sx={styleFont }>Description</TableCell>
                    <TableCell align="center" sx={styleFont }>coleur</TableCell>
                    <TableCell align="center"sx={styleFont }>Date_Debut</TableCell>
                    <TableCell align="center"sx={styleFont }>Date_Fin</TableCell>
                    <TableCell align="center"  sx={styleFont }>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                    {constructionstages.map((constructionstage)=>(
          <TableRow key={constructionstage.id_st_constr}>
          <TableCell align="center">{constructionstage.Stage_Name}</TableCell>
          <TableCell align="center">{constructionstage.Description}</TableCell>
          <TableCell align="center">{constructionstage.Couleur}</TableCell>
          <TableCell align="center">{constructionstage.Date_debut}</TableCell>
          <TableCell align="center">{constructionstage.Date_fin}</TableCell>

          <TableCell align="center">
            <Link to={"/ModifierConstructionStage/"+constructionstage.id_Stage}>
              <IconButton>
                <EditIcon sx={{ color: '#d35400' }} />
              </IconButton>
            </Link>
            <React.Fragment>
              <IconButton variant="outlined" onClick={handleClickOpenCon}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
              <Dialog
                open={openCon}
                onClose={handleCloseCon}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent sx={{ backgroundColor: '#d35400' }}>
                  <DialogContentText id="alert-dialog-description">
                    Vous avez sure de supprimer 
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#d35400' }}>
                  <Button onClick={handleCloseCon}>Non</Button>
                  <Button onClick={()=>handleDelete(constructionstage.id_Stage)}>Oui</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </TableCell>
        </TableRow>
        ))}
        

    </TableBody>
        </Table>
    </TableContainer>
</Appbar>
  )
}
