import React, { useEffect } from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function ModifierSousTypeConst() {
  const navigate = useNavigate()
  const {id} = useParams()
    const [formValues, setFormValues] = useState({
      id_st_constr: '',
      nom_st_constr: '',
      Descripsion:''
       
       
      });
      useEffect(()=>{
        axios.get("https://serv.construction.yuliagroupe.com/api/sous-types-construction/"+id)
        .then((res)=>{
          setFormValues({
            id_st_constr:res.data.data.id_st_constr,
            nom_st_constr:res.data.data.nom_st_constr,
            Descripsion:res.data.data.Descripsion,
            type_construction:res.data.data.type_construction,
            
          })
        })
      },[])
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.put("https://serv.construction.yuliagroupe.com/api/sous-types-construction/"+id,formValues)
            .then((res)=>{
              navigate("/SousTypeContsrc")
            })
          
          }
           
 
 
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '150vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
           Modifier Sous Type_Construction
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                 
                      <Grid item xs={6}>
                        <TextField fullWidth label="nom_Type" name="nom_st_constr" value={formValues.nom_st_constr} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="description" name="Descripsion" value={formValues.Descripsion} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} 
                          />
                      </Grid>
                    
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Modifier
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
