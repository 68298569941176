import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function Employes() {
  const styleFont = { fontWeight: 600 };
  const navigate = useNavigate();
  const [openCon, setOpenCon] = React.useState(false);
  const [employes, setEmployes] = useState([]);

  const handleClickOpenCon = () => {
    setOpenCon(true);
  };

  const handleCloseCon = () => {
    setOpenCon(false);
  };

  useEffect(() => {
    const isloged = JSON.parse(localStorage.getItem("isloged"));
    if (!isloged) {
      navigate("/");
    } else {
      axios.get("https://serv.construction.yuliagroupe.com/api/employes").then((res) => {
        console.log(res.data.data);
        setEmployes(res.data.data);
      });
    }
  }, []);

  const handleDelete = (id_employe) => {
    axios.delete(`https://serv.construction.yuliagroupe.com/api/employes/${id_employe}`)
      .then(response => {
        console.log('Utilisateur supprimé avec succès');
        // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
        const updatedUsers = employes.filter(employe => employe.id_employe !== id_employe);
        setEmployes(updatedUsers);
        handleCloseCon();
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de l\'utilisateur', error);
      });
  };

  return (
    <Appbar>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginLeft: '50px' }}>
        <Grid container spacing={2} alignItems="center">
        <Link to="/AjouterEmployes" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
              

          <Grid item sx={{ marginLeft: '500px', marginBottom: '15px' }}>
          <Link to="/EmpChant" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '90px',
      }}>
        <ChromeReaderModeIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Affecter_employer_chantier 
      </Button>
    </Link>  
          </Grid>
    
    
        </Grid>
      </Box>

      <TableContainer style={{ width: '1070px', marginLeft: '50px', borderRadius: '9px', border: '1px solid ', marginTop: '20px' }}>
        <Table sx={{ minWidth: 650, border: '0.1px solid #ddd' }} aria-label="simple table">
          <TableHead sx={{backgroundColor: '#e8e8e8'}}>
            <TableRow>
              <TableCell align="center" sx={styleFont}>Nom</TableCell>
              <TableCell align="center" sx={styleFont}>Prenom</TableCell>
              <TableCell align="center" sx={styleFont}>Adresse</TableCell>
              <TableCell align="center" sx={styleFont}>Matricule</TableCell>
              <TableCell align="center" sx={styleFont}>Email</TableCell>
              <TableCell align="center" sx={{ color: '#001F3F', fontWeight: 600 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employes.map((employe) => (
              <TableRow key={employe.id}>
                <TableCell align="center">{employe.nom}</TableCell>
                <TableCell align="center">{employe.prenom}</TableCell>
                <TableCell align="center">{employe.adresse}</TableCell>
                <TableCell align="center">{employe.matricule}</TableCell>
                <TableCell align="center">{employe.email}</TableCell>
                <TableCell align="center">
                  <Link to={'/ModifierEmployes/' + employe.id_employe}>
                    <IconButton><EditIcon sx={{ color: '#d35400' }} /></IconButton>
                  </Link>
                  <React.Fragment>
                    <IconButton variant="outlined" onClick={handleClickOpenCon}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(employe.id_employe)}>Oui</Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Appbar>
  );
}


export default Employes
