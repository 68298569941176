import React, { useEffect } from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default AjouterServices
function AjouterServices() {
 
  const navigate = useNavigate()

    const [formValues, setFormValues] = useState({
        ID_Services: '',
        nom_service: '',
        date_pointage:'',
        nom_employes:''
       });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
  const handleSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();
      axios.post('https://serv.construction.yuliagroupe.com/api/services', formValues)
      .then(response => {
        console.log('services ajouté avec succès', response.data.data);
        navigate('/Services');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout de service', error);
        });

  }


  const [employes , setEmployes] = useState([])
  useEffect(()=>{
    axios.get("https://serv.construction.yuliagroupe.com/api/employes")
    .then((res)=>{
      setEmployes(res.data.data)
    })
  },[])

  const [pointages , setPointage] = useState([])
  useEffect(()=>{
    axios.get("https://serv.construction.yuliagroupe.com/api/pointages")
    .then((res)=>{
      setPointage(res.data.data)
    })
  },[])





return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Services
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_service" value={formValues.id_service} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom_Service" name="nom_service" value={formValues.nom_service} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='pointages'
          onChange={handleChange}
          name='date_pointage'
value={formValues.date_pointage}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
 <option value="">Sélectionnez un pointage</option>
          {pointages.map((pointage)=>(
            <option key={pointage.id_pointage} value={pointage.id_pointage}>{pointage.date_pointage}</option>
          ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='nom_employes'
value={formValues.nom_employes}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
            <option value="">Sélectionnez un employe</option>

 {employes.map((employe)=>(
            <option key={employe.id_employe} value={employe.id_employe}>{employe.nom} {employe.prenom}</option>
          ))}
         
        </TextField>
                      </Grid>
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Ajouter
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}


