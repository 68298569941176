import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
function AjouterRisque() {
 
    const [formValues, setFormValues] = useState({
        nom_Risque: '',
        nom:'',
        nom_type_risque:''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
 
 
      //style
        
      const getInputProps = () => ({
        style: {
          borderRadius: "5px",
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          color: 'black', // Couleur du texte en noir
          
        },
      });
    
      const getSxProps = () => ({
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ccc', // Couleur de la bordure par défaut
          },
          '&:hover fieldset': {
            borderColor: '#bbb', // Couleur de la bordure au survol
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Couleur de la bordure au focus
          },
        },
      });
 
      const [typerisques , setTyperisques] = useState([])
      useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/types-risque")
      .then((res)=>{
        console.log(res.data.data)
        setTyperisques(res.data.data)
      })
      },[])



      const [employes , setEmployes] = useState([])
      useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/employes")
      .then((res)=>{
        console.log(res.data.data)
        setEmployes(res.data.data)
      })
      },[])

 
      const navigate=useNavigate()
      const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://serv.construction.yuliagroupe.com/api/types-risque', formValues)
        .then(response => {
          console.log('risque ajouté avec succès', response.data.data);
          navigate('/risque');
          })
          .catch(error => {
            console.error('Erreur lors de l\'ajout de risque', error);
      });
      
      }
 
 







 
    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Risque
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
         
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom_Risque" name="nom_Risque" value={formValues.nom_Risque} onChange={handleChange}   InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}   />
                  </Grid>
                  <Grid item xs={6}>
                      <TextField
        id="outlined-select-currency-native"
        select
        label='nom_employes'
        onChange={handleChange}
        name='nom'
 value={formValues.nom}
        SelectProps={{
          native: true,
        }}
        InputProps={{
          style: {
            borderRadius: "5px",
            backgroundColor: 'rgba(255, 255, 255, 95%)',
            width:'521px'
          },
        }} 
      >
 < option value="">Sélectionnez un employe</option>
        {employes.map((employe)=>(
          <option key={employe.id_employe} value={employe.id_employe}>{employe.nom} {employe.prenom} </option>
        ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
       id="outlined-select-currency-native"
       select
       label='type_risque'
       onChange={handleChange}
       name='nom_type_risque'
value={formValues.nom_type_risque}
       SelectProps={{
         native: true,
       }}
       InputProps={{
         style: {
           borderRadius: "5px",
           backgroundColor: 'rgba(255, 255, 255, 95%)',
           width:'521px'
         },
       }} 
     >
< option value="">Sélectionnez un type risque</option>
       {typerisques.map((typerisque)=>(
         <option key={typerisque.id_type_risque} value={typerisque.id_type_risque}>{typerisque.nom_type_risque}</option>
       ))}
         
        </TextField>
                      </Grid>
                
                
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}

export default AjouterRisque
