import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Box, Snackbar, Alert, TextField } from '@mui/material';
import Appbar from '../Appbar';
import { Grid, Typography } from '@mui/material';

import axios from 'axios';
import { useParams } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';
import { CheckCircleOutline, ErrorOutline, HelpOutline } from '@mui/icons-material';

export default function ModifierPointage() {
  const { id } = useParams()
  const [employeesPresence, setEmployeesPresence] = useState({});
  const [pointage, setpointage] = React.useState("")
  const [chantier, setchantier] = React.useState("")
  const [heureEntre, setHeureEntre] = useState('');
  const [heureSortie, setHeureSortie] = useState('');
const [idPointage, setIdPointage] = useState('');

React.useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/pointages/" + id)
      .then((res) => {
        setpointage(res.data.data)

      })
  }, [])
  const [employes, setEmployes] = useState([]);

  useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/AssociationEchantierEmployeController").then((res) => {
      console.log(res.data.data);
      setEmployes(res.data.data);
    });
  }, []);


  const getPresenceIcon = (employeeId) => {
    if (employeesPresence[employeeId] === 'Présent') {
      return <CheckCircleOutline style={{ color: 'green' }} />;
    } else if (employeesPresence[employeeId] === 'Demi-journée') {
      return <HelpOutline style={{ color: 'yellow' }} />;
    } else if (employeesPresence[employeeId] === 'Absent') {
      return <ErrorOutline style={{ color: 'red' }} />;
    }
    return null;
  };

  const green = () => {
    setHeureEntre('09:00:00')
    setHeureSortie('17:00:00')
  }

  const yellow = () => {
    setHeureEntre('09:00:00')
    setHeureSortie('13:00:00')
  }

  const red = () => {
    setHeureEntre('00:00:00')
    setHeureSortie('00:00:00')
  }


  const handlePresenceChange = (employeeId, presence) => {
    setEmployeesPresence(prevState => ({
      ...prevState,
      [employeeId]: presence,
    }));

    if (presence === 'Présent') {
      green()
    } else if (presence === "Demi-journée") {
      yellow()
    } else if (presence === "Absent") {
      red()
    }
    return null

  };

  const handleSavePresence = () => {
    console.log(employeesPresence)
    console.log(pointage)
    const payload = Object.keys(employeesPresence).map(employeeId => ({
      id_pointage: id,
      id_employe: employeeId,
      id_chantier : pointage[0].chantier.id_chantier,
      heure_entrer: heureEntre,
      heure_sorti: heureSortie,
    }))
    payload.map((data) => {
      console.log(data)
      axios.post("https://serv.construction.yuliagroupe.com/api/association_pointage_employe_chantier", data)
        .then((response) => {
          console.log("Présences enregistrées :", response.data);
  
        })
        .catch((error) => {
          console.error("There was an error saving the presence data!", error);
  
        });
  
    })
  };











return (
  <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginLeft: '200px' }}>
      <Grid container spacing={2} alignItems="center">

        <Grid item>
          <Typography variant="h6" sx={{ color: '#001f3f' ,fontWeight:'bold'}}>Pointage : {chantier.nom_chantier}</Typography>
        </Grid>
      </Grid>
    </Box>

    <TableContainer  style={{width:'1000px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
          <TableRow >
            <TableCell sx={{ fontWeight: 600 }} align='center'>Employes</TableCell>
            <TableCell align="center" sx={{ fontWeight: 600 }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employes.map((employe) => (
            <TableRow key={employe.id_pointage}>
              <TableCell align="center">{employe.employe.nom} {employe.employe.prenom}</TableCell>


              <TableCell align="center">
                <IconButton onClick={() => handlePresenceChange(employe.id_employe, 'Présent')}>
                  <CheckCircleOutline style={{ color: 'green' }} />
                </IconButton>
                <IconButton onClick={() => handlePresenceChange(employe.id_employe, 'Demi-journée')}>
                  <HelpOutline style={{ color: 'yellow' }} />
                </IconButton>
                <IconButton onClick={() => handlePresenceChange(employe.id_employe, 'Absent')}>
                  <ErrorOutline style={{ color: 'red' }} />
                </IconButton>
                {getPresenceIcon(employe.id_employe)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="contained" sx={{ marginTop: "25px", justifyContent: 'right', backgroundColor: '#d35400' }} onClick={handleSavePresence}>enregistrer</Button>

    </TableContainer>
  </Appbar>
)

}