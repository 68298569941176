import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function AjouterTypePrime() {
    const [formValues, setFormValues] = useState({
      id_type_prime: '',
      nom_type_prime: ''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
          const navigate = useNavigate();
 
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/typesprimes', formValues)
            .then(response => {
              console.log('TypePrime ajouté avec succès', response.data.data);
              navigate('/TypePrime');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de TypePrime', error);
              });
          };
  
  
  
  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Type_Prime
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_type_prime" value={formValues.id_type_prime} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom" name="nom_type_prime" value={formValues.nom_type_prime} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                    
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Ajouter
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}

export default AjouterTypePrime
