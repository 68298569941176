import React, { useEffect } from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function AjouterMatierePremier() {

  const navigate = useNavigate()

    const [formValues, setFormValues] = useState({
      id_matiere_premiere: '',
      Nom_Matière_Première: '',
      Description:''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
    const handleSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();
      axios.post('https://serv.construction.yuliagroupe.com/api/lesmatierspremiers', formValues)
      .then(response => {
        console.log('lesmatierspremier ajouté avec succès', response.data.lesmatierspremier);
        navigate('/matierePremier');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout de lesmatierspremier', error);
        });
    
    }


    return (
        <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Matiere Premier
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={6}>
                    <TextField fullWidth label="ID" name="id_matiere_premiere" value={formValues.id_matiere_premiere} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom" name="Nom_Matière_Première" value={formValues.Nom_Matière_Première} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="description" name="Description" value={formValues.Description} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} 
                      sx={{width:'700px'}}/>
                  </Grid>
                
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
