import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import {  IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid,  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useState,useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
function Risque() {
  const [formData, setFormData] = useState({
    nom_risque: ''
    
  });
 
    const [openCon, setOpenCon] = React.useState(false);
    const [risques, setRisques] = useState([]);
    const navigate = useNavigate()
    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
    

    
    useEffect(() => {
      const isloged = JSON.parse(localStorage.getItem("isloged"))
      if(!isloged){
        navigate("/")
      }else{
      axios.get("https://serv.construction.yuliagroupe.com/api/risques").then((res) => {
        console.log(res.data.data);
        setRisques(res.data.data);
      });
    }
    
    },[]);
 

    const handleDelete = (id_risque) => {
      axios.delete(`https://serv.construction.yuliagroupe.com/api/risques/${id_risque}`)
        .then(response => {
          console.log('risques supprimé avec succès');
          // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
          const updatedUsers = risques.filter(risque => risque.id_risque !== id_risque);
          setRisques(updatedUsers);
          handleCloseCon();
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de risques', error);
        });
    };
      
      






    return (
    <Appbar>
 <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'200px' }}>
                <Grid container spacing={2} alignItems="center" >
                <Link to="/AjouterRisque" style={{ textDecoration: 'none',marginRight:"600px" }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          alignSelf: 'flex-end',
          marginRight: '150px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>  
     
                  
                </Grid>
            </Box>
            <TableContainer  style={{width:'1000px',marginLeft:'120px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                    <TableRow>
                        <TableCell align="center"  sx={{ fontWeight: 600 }}>Nom_Risque</TableCell>
                        <TableCell align="center"  sx={{ fontWeight: 600 }}>Action</TableCell>

                       
                                </TableRow>
                </TableHead>
                <TableBody>
            {risques.map((risque) => (
              <TableRow key={risque.id}>
                <TableCell align="center">{risque.nom_risque}</TableCell>
                <TableCell align="center">
                  <Link to={'/ModifierRisque/'+risque.id_risque}>
                  {/* onClick={() => handleEdite(id_utilisateur)} */}
                    <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                  </Link>
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(risque.id_risque)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
            </Table>
        </TableContainer>
</Appbar>
  )
}

export default Risque
