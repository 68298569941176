
import React, { useEffect } from 'react'
import Appbar from './Appbar'
import { TextField, Button,  Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
function AjouterTypeConstr() {
  const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
        ID_TypeConstruction: '',
        Nom_TypeConstruction: '',
        nom_projet:''
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
          const handleSubmit = (e) => {
            e.preventDefault();
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/typesconstruction', formValues)
            .then(response => {
              console.log('typesconstructions ajouté avec succès', response.data.data);
              navigate('/TypeConstruction');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de typesconstruction', error);
              });
     
        }
 
const [projects , setProjects] = useState([])
  
    useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/projets")
      .then((res)=>{
        setProjects(res.data.data)
      })
    },[])
 
 
 
  
  
  
  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Type_Construction
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_typeconst" value={formValues.id_typeconst} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom" name="Nom_typeconst" value={formValues.Nom_typeconst} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='nom_projet'
          name='nom_projet'
          onChange={handleChange}
value={formValues.nom_projet}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >

  <option value="">Sélectionnez un projet</option>
          {projects.map((projet)=>(
            <option key={projet.id_projet} value={projet.id_projet}>{projet.nom_projet}</option>
          ))}
         
        </TextField>
                      </Grid>
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Ajouter
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}

export default AjouterTypeConstr
