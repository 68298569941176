import React from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

export default function ModifierTache() {
    const [formValues, setFormValues] = useState({
      id_tache: '',
      nom_tache: '',
      description_tache:'',
      date_debut: '',
      date_fin:'',
      durée_tache: ''
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
       
          const navigate = useNavigate();
          const {id} = useParams()
  
          useEffect(()=>{
            handleEdit()
           },[])
       
           const handleEdit = (id_tache) => {
            axios.get(`https://serv.construction.yuliagroupe.com/api/taches/${id}`)
            .then(response => {
                console.log(response.data.data)
                const userData = response.data.data; 
                setFormValues({
                  id_tache: userData.id_tache,
                  nom_tache: userData.nom_tache,
                  description_tache: userData.description_tache,
                  date_debut: userData.date_debut,
                  date_fin: userData.date_fin,
                  durée_tache: userData.durée_tache
                });
              })
              .catch(error => {
                console.error('Erreur lors de la récupération des données de l\'employes', error);
              });
          };    
 
     
          const handleEditSubmit = (e) => {
            e.preventDefault();
            axios.put(`https://serv.construction.yuliagroupe.com/api/taches/${id}`,formValues)
              .then(response => {
                console.log('taches modifié avec succès');
                navigate('/tache'); // Utilisez history.push au lieu de navigate
              })
              .catch(error => {
                console.error('Erreur lors de la modification de la taches', error);
              });
          };
           

  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Modifier Tache
                  </Typography>
                  <form onSubmit ={handleEditSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_tache" value={formValues.id_tache} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom_Tache" name="nom_tache" value={formValues.nom_tache} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Description" name="description_tache" value={formValues.description_tache} onChange={handleChange}    sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
        
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
                fullWidth
                type='date'
                name="date_debut"
                value={formValues.date_debut}
                onChange={handleChange}
                sx={{backgroundColor:'white'}}
                InputLabelProps={{
                  style: {
                    color: 'black' // Couleur du texte du label en noir
                  }
                }}
                inputProps={{
                    style: {
                    fontSize: '14px', // Taille de police personnalisée
                    },
                }}
/>
                          
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth type='date' name="date_fin" value={formValues.date_fin} onChange={handleChange}    sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}/>
                          
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth type='time'  name="durée_tache" value={formValues.durée_tache} onChange={handleChange}    sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                          
                      </Grid>
                      
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Modifier
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
