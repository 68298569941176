import React from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
export default function ModifierRole() {
 
    const [formValues, setFormValues] = useState({
     
      nom_role: ''
       });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
          const navigate = useNavigate();
           const {id} = useParams()
  
          useEffect(()=>{
            handleEdit()
           },[])

           const handleEdit = (id_role) => {
            axios.get(`https://serv.construction.yuliagroupe.com/api/roles/${id}`)
            .then(response => {
                console.log(response.data.role)
                const userData = response.data.role; 
                setFormValues({
                  id_role: userData.id_role,
                  nom_role: userData.nom_role,
                
                });
              })
              .catch(error => {
                console.error('Erreur lors de la récupération des données de l\'employes', error);
              });
          };
          
              
 
          const handleEditSubmit = (e) => {
            e.preventDefault();
            axios.put(`https://serv.construction.yuliagroupe.com/api/roles/${id}`,formValues)
              .then(response => {
                console.log('Roles modifié avec succès');
                navigate('/role'); // Utilisez history.push au lieu de navigate
              })
              .catch(error => {
                console.error('Erreur lors de la modification de role', error);
              });
          };
           
 
 
 




    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                 Modifier   Role
                  </Typography>
                  <form onSubmit ={handleEditSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                     
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom_Role" name="nom_role" value={formValues.nom_role} onChange={handleChange}   sx={{backgroundColor:'white',width:'98²0px'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      </Grid>
                     
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Modifier
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
