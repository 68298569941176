
import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

export default function AjouterEmpChant() {
  const navigate=useNavigate()
    const [formValues, setFormValues] = useState({
      id_employe: '',
        id_chantier: '',
     
      });




        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
          const[chantiers,setChantiers]=useState([])
          useEffect(() => {
            axios.get("https://serv.construction.yuliagroupe.com/api/chantiers").then((res) => {
              setChantiers(res.data.data)
            });
            },[]);
      
      
            const [employes, setEmployes] = useState([]);
      
            useEffect(() => {
              axios.get("https://serv.construction.yuliagroupe.com/api/employes").then((res) => {
                console.log(res.data.data);
                setEmployes(res.data.data);
              });
            },[]);
          





            const handleSubmit = (e) => {

              e.preventDefault();
              axios.post('https://serv.construction.yuliagroupe.com/api/AssociationEchantierEmployeController', formValues)
              .then(response => {
                console.log('tache ajouté avec succès', response.data.data);
                navigate('/EmpChant');
                })
                .catch(error => {
                  console.error('Erreur lors de l\'ajout ', error);
                });
            };
  
  
  
  
  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Ajouter Chantier a un Employes
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          name='id_chantier'
          onChange={handleChange}
          defaultValue="Chantier1"
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
< option value="">Sélectionnez un chantier</option>
          {chantiers.map((chantier)=>(
            <option key={chantier.id_chantier} value={chantier.id_chantier}>{chantier.nom_chantier}</option>
          ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          defaultValue="Employe1"
          name='id_employe'

          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }}   >
     < option value="">Sélectionnez un employes</option>
          {employes.map((employe)=>(
            <option key={employe.id_employe} value={employe.id_employe}>{employe.nom} {employe.prenomprenom}</option>
          ))}
         
        </TextField>
                      </Grid>
              
                 


                 
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Confirmer
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
 
                  </Appbar>
  )
 
 
 
    
}
