import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
export default function AjoutUtilisateur() {
  const [formValues, setFormValues] = useState({
        id_role: '',
        nom_utilisateur: '',
        prenom_utilisateur: '',
        mot_de_pass:'',
        Email: ''
       
      });
      const navigate = useNavigate();  

        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  const [roles,setRoles]=useState([])
         useEffect(() => {
       axios.get("https://serv.construction.yuliagroupe.com/api/roles").then((res) => {
                console.log(res.data.roles);
                setRoles(res.data.roles);
          })
        },[])
 
  




          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/utilisateurs', formValues)
            .then(response => {
              console.log('Utilisateur ajouté avec succès', response.data.utilisateur);
              navigate('/utilisateur');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de l\'utilisateur', error);
              });
          };


          //style
        
  const getInputProps = () => ({
    style: {
      borderRadius: "5px",
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      color: 'black', // Couleur du texte en noir
      
    },
  });

  const getSxProps = () => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc', // Couleur de la bordure par défaut
      },
      '&:hover fieldset': {
        borderColor: '#bbb', // Couleur de la bordure au survol
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Couleur de la bordure au focus
      },
    },
  });
  
  
    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Ajouter Utilisateur
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
         
                <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='role'
          onChange={handleChange}
          name='id_role'
value={formValues.id_role}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }} 
        >
 <option value="">Sélectionnez un role</option>
          {roles.map((role)=>(
            <option key={role.id_role} value={role.id_role}>{role.nom_role}</option>
          ))}
         
        </TextField>
                      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Nom"
          name="nom_utilisateur"
          value={formValues.nom_utilisateur}
          onChange={handleChange}
          InputProps={getInputProps()} 
          sx={getSxProps()} 
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Prenom"
          name="prenom_utilisateur"
          value={formValues.prenom_utilisateur}
          onChange={handleChange}
          InputProps={getInputProps()} 
          sx={getSxProps()} 
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="password"
          fullWidth
          label="Mot de Pass"
          name="mot_de_pass"
          value={formValues.mot_de_pass}
          onChange={handleChange}
          InputProps={getInputProps()} 
          sx={getSxProps()} 
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="email"
          fullWidth
          label="Email"
          name="Email"
          value={formValues.Email}
          onChange={handleChange}
          InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}
        />
      </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right',  }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', 
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
