import './App.css';
import Appbar from './Appbar';
import Dashboard from './Dashboard';
import Login from './Login';
import Utilisateur from './Utilisateur';
import React, { useState } from 'react';
import Tache from './Tache';
import Entreprise from './Entreprise';
import Employes from './Employes';
import Chantier from './Chantier';
import Risque from './Risque';
import Stock from './Stock';
import Projet from './Projet';
import { BrowserRouter, Route ,Routes} from 'react-router-dom';
import Construction_stage from './Construction_stage';
import ReportingEtAnaylyse from './ReportingEtAnaylyse';
import Fournisseur from './Fournisseur';
import AjoutFournisseur from './AjoutFournisseur';
import AjoutUtilisateur from './AjoutUtilisateur';
import AjouterEmployes from './AjouterEmployes';
import AjouterTache from './AjouterTache';
import AjouterRisque from './AjouterRisque';
import AjouterStock from './AjouterStock';
import Produits from './Produits';
import AjouterProduits from './AjouterProduits';
import AjouterRole from './AjouterRole';
import AjouterChantier from './AjouterChantier';
import AjouterConstructionStage from './AjouterConstructionStage';
import AjouterEntps from './AjouterEntps';
import AjouterProjet from './AjouterProjet';
import AjouterMateriel from './AjouterMateriel';
import MatérielFournisseur from './MatérielFournisseur';
import Matierepremier from './Matierepremier';
import AjouterMatierePremier from './AjouterMatierePremier';
import AjouterMaterielInterne from './AjouterMaterielInterne';
import MaterielInterne from './MaterielInterne';
import Typeconstruction from './Typeconstruction';
import AjouterTypeConstr from './AjouterTypeConstr';
import TypePrime from './TypePrime';
import AjouterTypePrime from './AjouterTypePrime';
import TypeRisque from './TypeRisque';
import AjouterTypeRisque from './AjouterTypeRisque';
import SouTypeConstrc from './SouTypeConstrc';
import AjouterSoustypeConstrc from './AjouterSoustypeConstrc';
import Services from './Services';
import AjouterServices from './AjouterServices';
import Département from './Département';
import AjouterDépartement from './AjouterDépartement';
import ModifierUtilisateur from './UpdatesComponente/ModifierUtilisateur';
import ModifierEmployes from './UpdatesComponente/ModifierEmployes';
import ModifierTache from './UpdatesComponente/ModifierTache';
import ModifierServices from './UpdatesComponente/ModifierServices';
import ModifierFournniseur from './UpdatesComponente/ModifierFournniseur';
import ModifierRisque from './UpdatesComponente/ModifierRisque';
import ModifierDépartement from './UpdatesComponente/ModifierDépartement';
import ModifierStock from './UpdatesComponente/ModifierStock';
import ModifierProjet from './UpdatesComponente/ModifierProjet';
import ModifierTypeConstrc from './UpdatesComponente/ModifierTypeConstrc';
import ModifierTypePrime from './UpdatesComponente/ModifierTypePrime';
import ModifierTypeRisque from './UpdatesComponente/ModifierTypeRisque';
import ModifierSousTypeConst from './UpdatesComponente/ModifierSousTypeConst';
import ModifierChantier from './UpdatesComponente/ModifierChantier';
import ModifierConstrcStage from './UpdatesComponente/ModifierConstrcStage';
import ModifierEntps from './UpdatesComponente/ModifierEntps';
import ModifierMateriel from './UpdatesComponente/ModifierMateriel';
import ModifierMaterielInterne from './UpdatesComponente/ModifierMaterielInterne';
import ModifierMatierPremi from './UpdatesComponente/ModifierMatierPremi';
import ModifierProduits from './UpdatesComponente/ModifierProduits';
import ModifierRole from './UpdatesComponente/ModifierRole';
import Role from './Role';
import Maps from './Maps';
import Pointage from './Pointage';
import ModifierPointage from './UpdatesComponente/ModifierPointage';
import AffectEmpChant from './AffectEmpChant';
import AjouterEmpChant from './AjouterEmpChant';
import AffectTachUser from './AffectTachUser';
import ModifierTacheUser from './ModifierTacheUser';
import TimeLine from './TimeLine';
import AjouterTimeline from './AjouterTimeline';
import AffectMatierPrStock from './AffectMatierPrStock'
import AjouterMatierStock from './AjouterMatierStock';
import AffectProduitStock from './AffectProduitStock';
import AjouterProdStock from './AjouterProdStock';
import AjouterPointage from './AjouterPointage';
import Previlage from './Previlage';
import AjouterPrevilage from './AjouterPrevilage';
import ModifierPrevilage from './UpdatesComponente/ModifierPrevilage';
import AjouterRolPermission from './AjouterRolPermission';
import ModifierRolePermission from './UpdatesComponente/ModifierRolePermission';
import AffePrevilageRole from './AffePrevilageRole'
function App() {

  
  return (
  
    <div className="App">
    <BrowserRouter> 
    <Routes>
       
      <Route path="/" element={<Login />} />
      {/* <Route path='*' element={< Appbar/>}/> */}

       
         
          <Route path="/utilisateur" element={<Utilisateur />} />
          <Route path="*" element={<Dashboard />} />
          <Route path="/tache" element={<Tache />} />
          <Route path="/entreprise" element={<Entreprise />} />
          <Route path="/employes" element={<Employes />} />
          <Route path="/Role" element={<Role />} />

          <Route path="/chantier" element={<Chantier />} />
          <Route path="/risque" element={<Risque />} />
          <Route path="/stock" element={<Stock />} />
          <Route path="/projet" element={<Projet />} />
          <Route path="/construction_stage" element={<Construction_stage />} />

          <Route path="/ReportingEtAnaylyse" element={<ReportingEtAnaylyse />} />
          <Route path="/Fournisseur" element={<Fournisseur />} />
          <Route path="/Produits" element={<Produits />} />
          <Route path="/materiel" element={<MatérielFournisseur />} />
          <Route path="/matierePremier" element={< Matierepremier/>} />
          //
          <Route path="/materielInterne" element={< MaterielInterne/>} />
          <Route path="/TypeConstruction" element={< Typeconstruction/>} />
          <Route path="/TypePrime" element={< TypePrime/>} />
          <Route path="/TypeRisque" element={< TypeRisque/>} />
          <Route path="/SousTypeContsrc" element={< SouTypeConstrc/>} />
          <Route path="/Services" element={< Services/>} />
          <Route path="/Département" element={< Département/>} />



          <Route path="/previlage" element={< Previlage/>} />

          <Route path="/Map" element={< Maps/>} />
          <Route path="/Pointage" element={< Pointage/>} />
          <Route path="/EmpChant" element={< AffectEmpChant/>} />
          <Route path="/AffectTachUser" element={< AffectTachUser/>} />
          <Route path="/Timeline" element={< TimeLine/>} />
          <Route path="/AffectMatierStock" element={< AffectMatierPrStock/>} />
      
          <Route path="/AffectProdStock" element={< AffectProduitStock/>} />
          <Route path="/AffePrevilageRole" element={< AffePrevilageRole/>} />
          <Route path="/AjoutFournisseur" element={<AjoutFournisseur />} />
          <Route path="/AjoutUtilisateur" element={<AjoutUtilisateur />} />
          <Route path="/AjouterEmployes" element={<AjouterEmployes />} />
          <Route path="/AjouterTache" element={<AjouterTache />} />
          <Route path="/AjouterRisque" element={<AjouterRisque />} />
          <Route path="/AjouterStock" element={<AjouterStock />} />
          <Route path="/AjouterProduits" element={<AjouterProduits />} />
          <Route path="/AjouterRole" element={<AjouterRole />} />
          <Route path="/AjouterChantier" element={<AjouterChantier />} />
          <Route path="/AjoutConstructionStage" element={<AjouterConstructionStage />} />
          <Route path="/AjouterEntreprise" element={<AjouterEntps />} />
          <Route path="/AjouterProjet" element={<AjouterProjet />} />
          <Route path="/AjouterMateriel" element={<AjouterMateriel />} />
          <Route path="/AjouterMatierePremier" element={<AjouterMatierePremier />} />
          <Route path="/AjouterMaterielInterne" element={<AjouterMaterielInterne />} />
          <Route path="/AjouterTypeConstruction" element={<AjouterTypeConstr />} />
          <Route path="/AjouterTypePrime" element={<AjouterTypePrime />} />
          <Route path="/AjouterTypeRisque" element={<AjouterTypeRisque />} />
          <Route path="/AjouterSousTypeConstr" element={<AjouterSoustypeConstrc />} />
          <Route path="/AjouterServices" element={<AjouterServices />} />
          <Route path="/AjouterDépartement" element={<AjouterDépartement />} />
          <Route path="/AjouterEmpChant" element={< AjouterEmpChant/>} />
          <Route path="/ModifierTacheUser" element={< ModifierTacheUser/>} />
          <Route path="/AjouterTimeline" element={< AjouterTimeline/>} />
          <Route path="/AjouterTimeline" element={< AjouterTimeline/>} />
          <Route path="/AjouterMatiStock" element={< AjouterMatierStock/>} />
          <Route path="/AjouterprodStock" element={< AjouterProdStock/>} />
          <Route path="/AjouterPointage" element={< AjouterPointage/>} />
          <Route path="/AjouterPrevilage" element={< AjouterPrevilage/>} />
          <Route path="/AjouterRolPermision" element={< AjouterRolPermission/>} />


          



          <Route path="/ModifierUtilisateur/:id" element={<ModifierUtilisateur />} />
          <Route path="/ModifierEmployes/:id" element={<ModifierEmployes />} />
          <Route path="/ModifierTache/:id" element={<ModifierTache />} />
          <Route path="/ModifierServices/:id" element={<ModifierServices />} />
          <Route path="/ModifierFournisseur/:id" element={<ModifierFournniseur />} />
          <Route path="/ModifierRisque/:id" element={<ModifierRisque />} />
          <Route path="/ModifierDépartement/:id" element={<ModifierDépartement />} />
          <Route path="/ModifierStock/:id" element={<ModifierStock />} />
          <Route path="/ModifierProjet/:id" element={<ModifierProjet />} />
          <Route path="/ModifierTypeConstr/:id" element={<ModifierTypeConstrc />} />
          <Route path="/ModifierTypePrime/:id" element={<ModifierTypePrime />} />
          <Route path="/ModifierTypeRisque/:id" element={<ModifierTypeRisque />} />
          <Route path="/ModifierSousTypeConstr/:id" element={<ModifierSousTypeConst />} />
          <Route path="/ModifierChantier/:id" element={<ModifierChantier/>} />
          <Route path="/ModifierConstructionStage/:id" element={<ModifierConstrcStage/>} />
          <Route path="/modifierEntreprise/:id" element={<ModifierEntps/>} />
          <Route path="/ModifierMateriel/:id" element={<ModifierMateriel/>} />
          <Route path="/ModifierMaterielInterne/:id" element={<ModifierMaterielInterne/>} />
          <Route path="/ModifierMatierePremier/:id" element={<ModifierMatierPremi/>} />
          <Route path="/ModifierProduits/:id" element={<ModifierProduits/>} />
          <Route path="/ModifierRole/:id" element={<ModifierRole/>} />
          <Route path="/ModifierPointage/:id" element={<ModifierPointage/>} />
          <Route path="/ModifierPrevilage/:id" element={<ModifierPrevilage/>} />
          <Route path="/ModifierRolPermission" element={<ModifierRolePermission/>} />

     {/* Ajoutez d'autres routes selon vos besoins */}
        </Routes>
    </BrowserRouter>
      </div>
   
  );
}

export default App;
