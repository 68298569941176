import React from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
export default function ModifierRolePermission() {
  
  
    const [formValues, setFormValues] = useState({
        Role: '',
        Permission: ''
       });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
 
    const handleSubmit = (e) => {
        e.preventDefault();
 
    }
 
  
  
  
  
  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
               affecter Role Permission
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={6}>
                      <TextField
                    
          id="outlined-select-currency-native"
          aria-label='assiné a'
value={formValues.Role}
          select
          label=" Role"

          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
  <option value="u1">  </option>
  <option value="u2">Role2</option>
  <option value="u3">Role 3</option>
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
                    
          id="outlined-select-currency-native"
          aria-label='assiné a'
value={formValues.Permission}
          select
          label=" Permission"

          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
  <option value="u1">  </option>
  <option value="u2">permision2</option>
  <option value="u3">permission 3</option>
         
        </TextField>
                      </Grid>
                      </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Modifier
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
