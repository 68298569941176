import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import { useEffect } from 'react';
export default function AffePrevilageRole() {

    const [openCon, setOpenCon] = React.useState(false);

    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
    useEffect(() => {
      getData();
    },[]);
    const [previlagroles,setPrevilagroles]=React.useState([])
    const getData = () => {
      axios.get("https://serv.construction.yuliagroupe.com/api/association-privileges-roles")
        .then((res) => {
          console.log(res.data.associations);
          setPrevilagroles(res.data.associations);
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        });
    };

    
    
    const handleDelete = (id_privilege,id_role) => {
      axios.delete(`https://serv.construction.yuliagroupe.com/api/association-privileges-roles/${id_privilege}/${id_role}`)
        .then(response => {
          console.log(response);


          getData()
          // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
          handleCloseCon();
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de l affectaton', error);
        });
    };
    
    
    
    
    return (
        <Appbar>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                <Link to="/AjouterRolPermision">        <IconButton>
                        <AddCircleIcon sx={{color:'#d35400'}}/>
                    </IconButton></Link>
                </Grid>
                <Grid item>
                    <Typography variant="h6" sx={{color:'#001f3f'}}>Affectation Role_Permission </Typography>
                </Grid>
            </Grid>
        </Box>
    
        <TableContainer  style={{width:'1070px',marginLeft:'50px'}} >
            <Table sx={{ minWidth: 650, backgroundColor: '#C5C5C5' }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Role</TableCell>
                        <TableCell align="center"> Permission</TableCell>
                        <TableCell align="center" sx={{color:'#001F3F'}}>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {previlagroles.map((previlagrole) => (
              <TableRow key={previlagrole.id_privilege}>
                <TableCell align="center">{previlagrole.role.nom_role}</TableCell>
                <TableCell align="center">{previlagrole.privilege.permission}</TableCell>
                <TableCell align="center">
              
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(previlagrole.id_privilege,previlagrole.id_role)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </TableContainer>
</Appbar>
  
  
  
  
  
  
  
  )
}
