import React, { useEffect } from 'react'
import Appbar from './Appbar'
import { TextField, Button, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

function AjouterDépartement() {
  
  const navigate = useNavigate()


    const [formValues, setFormValues] = useState({
      id_departement: '',
      nom_departement: '',
        nom_service:''
       });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
   const handleSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();
      axios.post('https://serv.construction.yuliagroupe.com/api/departements', formValues)
      .then(response => {
        console.log('departements ajouté avec succès', response.data.data);
        navigate('/Département');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout de departements', error);
        });

  }


  const [services , setServices] = useState([])
  useEffect(()=>{
    axios.get("https://serv.construction.yuliagroupe.com/api/services")
    .then((res)=>{
      setServices(res.data.data)
    })
  },[])

 return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Département
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_departement" value={formValues.id_departement} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Nom_Département" name="nom_departement" value={formValues.nom_departement} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
        <TextField
          id="outlined-select-currency-native"
          select
          label='Nom_service'
          name='nom_service'
          value={formValues.nom_service}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
            <option value="">Sélectionnez un service</option>

         {services.map((service)=>(
            <option key={service.id_service} value={service.id_service}>{service.nom_service} </option>
          ))}
        </TextField>
      </Grid>
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Ajouter
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}

export default AjouterDépartement
