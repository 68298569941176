
import React, { useEffect } from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
function ModifierUtilisateur() {
  const navigate = useNavigate();
  const {id} = useParams()
  const [formValues, setFormValues] = useState({
    id_role: '',
    nom_utilisateur: '',
    prenom_utilisateur: '',
    mot_de_pass:'',
    Email: ''
   
  });
 
 
 
 




  useEffect(()=>{
  handleEdit()
 },[])
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };



const handleEdit = (id_utilisateur) => {
  axios.get(`https://serv.construction.yuliagroupe.com/api/utilisateurs/${id}`)
    .then(response => {
      console.log(response.data)
      const userData = response.data.utilisateur; 
      setFormValues({
        id_utilisateur: userData.id_utilisateur,
        id_role: userData.id_role,
        nom_utilisateur: userData.nom_utilisateur,
        prenom_utilisateur: userData.prenom_utilisateur,
        mot_de_pass: userData.mot_de_pass,
        Email: userData.Email
      });
    })
    .catch(error => {
      console.error('Erreur lors de la récupération des données de l\'utilisateur', error);
    });
};

const [roles,setRoles]=useState([])
useEffect(() => {
axios.get("http://localhost:8000/api/roles").then((res) => {
       console.log(res.data.roles);
       setRoles(res.data.roles);
 })
},[])


const handleEditSubmit = (e) => {
  e.preventDefault();
  axios.put(`https://serv.construction.yuliagroupe.com/api/utilisateurs/${id}`,formValues)
    .then(response => {
      console.log('Utilisateur modifié avec succès');
      navigate('/utilisateur'); // Utilisez history.push au lieu de navigate
    })
    .catch(error => {
      console.error('Erreur lors de la modification de l\'utilisateur', error);
    });
};
 
 
  return (
<Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Modifier Utilisateur
              </Typography>
              <form onSubmit ={handleEditSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              
                <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='role'
          onChange={handleChange}
          name='id_role'
value={formValues.id_role}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }} 
        >
 <option value="">Sélectionnez un role</option>
          {roles.map((role)=>(
            <option key={role.id_role} value={role.id_role}>{role.nom_role}</option>
          ))}
         
        </TextField>
                      </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom" name="nom_utilisateur" value={formValues.nom_utilisateur} onChange={handleChange}     sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Prenom_Utilisateur" name="prenom_utilisateur" value={formValues.prenom_utilisateur} onChange={handleChange}      sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}/>
    
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='password' fullWidth label="Motdepass" name="mot_de_pass" value={formValues.mot_de_pass} onChange={handleChange}     sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}/>
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='email' fullWidth label="Email" name="Email" value={formValues.Email} onChange={handleChange}      sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}
                     
                      />
                      
                  </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}
        >

                    Modifier
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}

export default ModifierUtilisateur
