
import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function AjouterTimeline() {
    const [formValues, setFormValues] = useState({
      id_projet:'',
      id_Stage:'',
      date_estimation:'',
        Date_debut:'',
        Date_fin:''
      });
  
  
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };
    






       //style
        
       const getInputProps = () => ({
        style: {
          borderRadius: "5px",
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          color: 'black', // Couleur du texte en noir
        },
      });
    
      const getSxProps = () => ({
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ccc', // Couleur de la bordure par défaut
          },
          '&:hover fieldset': {
            borderColor: '#bbb', // Couleur de la bordure au survol
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Couleur de la bordure au focus
          },
        },
      });
      
const navigate=useNavigate()
      const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://serv.construction.yuliagroupe.com/api/timelines', formValues)
        .then(response => {
          console.log('timeline ajouté avec succès', response.data.data);
          navigate('/timeline');
          })
          .catch(error => {
            console.error('Erreur lors de l\'ajout de timeline', error);
      });
      
      }








      const [projets , setProjets] = useState([])
      useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/projets")
      .then((res)=>{
        setProjets(res.data.data)
      })
      },[])
      const [stages , setStages] = useState([])
      useEffect(()=>{
      axios.get("https://serv.construction.yuliagroupe.com/api/stages")
      .then((res)=>{
        setStages(res.data.data)
      })
      },[])

  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Ajouter Un TimeLine
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                      <TextField
         id="outlined-select-currency-native"
         select
         label='Stage_Name'
         onChange={handleChange}
         name='id_Stage'
  value={formValues.id_Stage}
         SelectProps={{
           native: true,
         }}
         InputProps={{
           style: {
             borderRadius: "5px",
             backgroundColor: 'rgba(255, 255, 255, 95%)',
             width:'521px'
           },
         }} 
       >
< option value="">Sélectionnez un projet</option>
         {stages.map((stage)=>(
           <option key={stage.id_Stage} value={stage.id_Stage}>{stage.Stage_Name}</option>
         ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='projet'
          onChange={handleChange}
          name='id_projet'
   value={formValues.id_projet}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }} 
        >
< option value="">Sélectionnez un projet</option>
          {projets.map((projet)=>(
            <option key={projet.id_projet} value={projet.id_projet}>{projet.nom_projet}</option>
          ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                    <TextField label='date_déstimationdefinition' type='date' fullWidth  name="date_estimation" value={formValues.date_estimation} onChange={handleChange}   InputProps={getInputProps()} 
sx={getSxProps()}
InputLabelProps={{
  style: {
    color: 'black', // Couleur du texte du label en noir,
  }
}}/>
                      
                  </Grid>
                      <Grid item xs={6}>
                    <TextField type='date' fullWidth  name="Date_debut" value={formValues.Date_debut} onChange={handleChange}     InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth  name="Date_fin" value={formValues.Date_fin} onChange={handleChange}     InputProps={getInputProps()} 
          sx={getSxProps()}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                      
                  </Grid>


                 
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Confirmer
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
 
                  </Appbar>
  )
}
