import React, { useEffect } from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { useNavigate } from 'react-router';

export default function ModifierDépartement() {
  
  const navigate = useNavigate()
  const {id} = useParams()
  const [formValues, setFormValues] = useState({
    nom_departement: '',
      
    });
useEffect(()=>{
  axios.get("https://serv.construction.yuliagroupe.com/api/departements/"+id)
  .then((res)=>{
    setFormValues({
      nom_departement:res.data.data.nom_departement
      
    })
  })
},[])
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    };
  
const handleSubmit = (e) => {
  e.preventDefault();
  axios.put("https://serv.construction.yuliagroupe.com/api/departements/"+id,formValues)
  .then((res)=>{
    navigate("/Département")
  })

}
 
 
  
  
    return (
      <Appbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: '80px'
        }}
      >
        <Paper elevation={3} sx={{ padding: '20px', width: '600px', borderRadius: '5px', backgroundColor: '#C5C5C5' }}>
          <Typography variant="h5" align="center" sx={{ color: '#001f3f', fontWeight: 'bold' }}>
            Modifier Departement
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ marginTop: '20px', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nom"
                  name="nom_departement"
                  value={formValues.nom_departement}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: 'rgba(255, 255, 255, 95%)'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#d35400',
                  color: '#001f3f',
                  '&:hover': {
                    backgroundColor: '#d35400'
                  }
                }}
              >
                Modifier
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Appbar>



  )
}
