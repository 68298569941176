import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
export default function AffectTachUser() {
    const [openCon, setOpenCon] = React.useState(false);
    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
const handleCloseCon = () => {
    
      setOpenCon(false);
    };
  
    const [taches,setTaches]=useState([])
    useEffect(() => {
      axios.get("https://serv.construction.yuliagroupe.com/api/taches").then((res) => {
        console.log(res.data.data);
        setTaches(res.data.data)
      });
    },[]);




      

  





    return (
    <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
        <Grid container spacing={2} alignItems="center">
          
        </Grid>
    </Box>

    <TableContainer  style={{width:'1070px',marginLeft:'50px'}} >
        <Table sx={{ minWidth: 650, backgroundColor: '#C5C5C5' }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="center">tache</TableCell>
                    <TableCell align="center">Assiné Par</TableCell>
                    <TableCell align="center">Assiné a</TableCell>
                    <TableCell align="center">Validée</TableCell>
                    <TableCell align="center">Date Debut</TableCell>
                    <TableCell align="center">Date Fin</TableCell>

                    <TableCell align="center" sx={{color:'#001F3F'}}>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {taches.map((tache) => (
              <TableRow key={tache.id_tache}>
                <TableCell align="center">{tache.nom_tache}</TableCell>
                <TableCell align="center">{tache.assigner_pars[0] ? tache.assigner_pars[0].utilisateur ?tache.assigner_pars[0].utilisateur.nom_utilisateur:null : null} </TableCell>
                <TableCell align="center">{tache.assenieras[0] ? tache.assenieras[0].utilisateur ?tache.assenieras[0].utilisateur.nom_utilisateur:null : null}</TableCell>
                <TableCell align="center">{tache.valider_pars[0] ? tache.valider_pars[0].utilisateur ?tache.valider_pars[0].utilisateur.nom_utilisateur:null : null}</TableCell>
                <TableCell align="center">{tache.date_debut}</TableCell>
                <TableCell align="center">{tache.date_fin}</TableCell>
                <TableCell align="center">
                  <Link to='/ModifierTacheUser'>
                  {/* onClick={() => handleEdite(id_utilisateur)} */}
                    <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                  </Link>
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </TableContainer>
</Appbar>
  )
}
