import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function AjouterStock() {
 
    const [formValues, setFormValues] = useState({
      id_stock: '',
      quantite: ''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
          const navigate = useNavigate();  

 
 
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/stocks', formValues)
            .then(response => {
              console.log('stock ajouté avec succès', response.data.stocks);
              navigate('/stock');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de stock', error);
              });
          };
  
 
 
 
 
 
 
 
 return (
<Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                STOCK
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={6}>
                    <TextField fullWidth label="ID" name="id_stock" value={formValues.id_stock} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth type='number' label="Quantité" name="quantite" value={formValues.quantite} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "5px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
