import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import {  IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export default function MatérielFournisseur() {


const[materiels,setMateriels]=useState([])
const navigate = useNavigate()
useEffect(() => {
  const isloged = JSON.parse(localStorage.getItem("isloged"))
  if(!isloged){
    navigate("/")
  }else{
        axios.get("https://serv.construction.yuliagroupe.com/api/materiels-fournisseurs").then((res) => {
            setMateriels(res.data.materiels)
          });
        }
      
      },[]);

        const handleDelete = (id_materiel) => {
            axios.delete(`https://serv.construction.yuliagroupe.com/api/materiels-fournisseurs/${id_materiel}`)
              .then(response => {
                console.log('materiel_fournisseur supprimé avec succès');
                
                const updatedUsers = materiels.filter(materiel => materiel.id_materiel !== id_materiel);
                setMateriels(updatedUsers);
                handleCloseCon();
              })
              .catch(error => {
                console.error('Erreur lors de la suppression de l\'types-risque', error);
                });
              }; 
          

        const [openCon, setOpenCon] = React.useState(false);

        const handleClickOpenCon = () => {
          setOpenCon(true);
        };
        
        const handleCloseCon = () => {
        
          setOpenCon(false);
        };
    
        
        return (
        <Appbar>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
    <Grid container spacing={2} alignItems="center">
    <Link to="/AjouterMateriel" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>  
    </Grid>
</Box>

<TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                <TableRow>
                    <TableCell align="center">Nom_Materiel</TableCell>
                    <TableCell align="center">Quantité_Materiel</TableCell>
                    <TableCell align="center">Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                    {materiels.map((materiel)=>(
          <TableRow key={materiel.id_materiel}>
          <TableCell align="center">{materiel.nom_materiel}</TableCell>
          <TableCell align="center">{materiel.quantité_materiel}</TableCell>
          <TableCell align="center">
            <Link to={"/ModifierMateriel/"+materiel.id_materiel}>
              <IconButton>
                <EditIcon sx={{ color: '#d35400' }} />
              </IconButton>
            </Link>
            <React.Fragment>
              <IconButton variant="outlined" onClick={handleClickOpenCon}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
              <Dialog
                open={openCon}
                onClose={handleCloseCon}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent sx={{ backgroundColor: '#d35400' }}>
                  <DialogContentText id="alert-dialog-description">
                    Vous avez sure de supprimer 
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#d35400' }}>
                  <Button onClick={handleCloseCon}>Non</Button>
                  <Button onClick={()=>handleDelete(materiel.id_materiel)}>Oui</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </TableCell>
        </TableRow>
        ))}
        

    </TableBody>
        </Table>
    </TableContainer>
</Appbar>




  )
}
