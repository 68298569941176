import React, { useEffect } from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function ModifierConstrcStage() {
  
  const navigate = useNavigate()
  const {id} = useParams()
    const [formValues, setFormValues] = useState({
      id_Stage: '',
      Stage_Name: '',
      Description: '',
      Couleur: '',
      Date_debut:'',
      Date_fin: ''
       
      });
      useEffect(()=>{
        axios.get("https://serv.construction.yuliagroupe.com/api/stages/"+id)
        .then((res)=>{
          setFormValues({
            Stage_Name:res.data.data.Stage_Name,
            Description:res.data.data.Description,
            Couleur:res.data.data.Couleur,
            Date_debut:res.data.data.Date_debut,
            Date_fin:res.data.data.Date_fin,
            id_Stage:res.data.data.id_Stage,
          })
        })
      },[])
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
    const handleSubmit = (e) => {
      e.preventDefault();
      axios.put("https://serv.construction.yuliagroupe.com/api/stages/"+id,formValues)
      .then((res)=>{
        navigate("/Construction_Stage")
      })
    
    }
     
 
 
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '160vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Modifier Constructer_Stage
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    
                      <Grid item xs={6}>
                        <TextField fullWidth label="stage_name" name="Stage_Name" value={formValues.Stage_Name} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="description" name="Description" value={formValues.Description} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="coleur" name="Couleur" value={formValues.Couleur} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}/>
        
                      </Grid>
                      <Grid item xs={6}>
                        <TextField type='date' fullWidth label="date_debut" name="Date_debut" value={formValues.Date_debut} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}/>
                          
                      </Grid>
                      <Grid item xs={6}>
                        <TextField type='date' fullWidth label="date_fin" name="Date_fin" value={formValues.Date_fin} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}
                         
                          />
                          
                      </Grid>
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',


            marginLeft:'140vh',
            color: '#001f3f',
          
            '&:hover': {
              backgroundColor: '#d35400', 
              // Garder la même couleur lors du survol
            },}}>
                        Modifier
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
