import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import {  IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function Produits() {
    const [openCon, setOpenCon] = React.useState(false);
    const navigate = useNavigate();
    const handleClickOpenCon = () => {
        setOpenCon(true);
      };
      
      const handleCloseCon = () => {
      
        setOpenCon(false);
      };
  
 const [produits, setProduits] = useState([]);
  
 useEffect(() => {
  const isloged = JSON.parse(localStorage.getItem("isloged"))
  if(!isloged){
    navigate("/")
  }else{
      axios.get("https://serv.construction.yuliagroupe.com/api/produits").then((res) => {
        console.log(res.data.data);
        setProduits(res.data.data);
      });
    }
    
    },[]);
      
         const handleDelete = (id_produit) => {
        axios.delete(`https://serv.construction.yuliagroupe.com/api/produits/${id_produit}`)
          .then(response => {
            console.log('products supprimé avec succès');
            // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
            const updatedUsers = produits.filter(produit => produit.id_produit !== id_produit);
            setProduits(updatedUsers);
            handleCloseCon();
          })
          .catch(error => {
            console.error('Erreur lors de la suppression de produit', error);
          });
      };
        
  
    return (
    <Appbar>
 <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'75px' }}>
                <Grid container spacing={2} alignItems="center">
                <Link to="/AjouterProduits" style={{ textDecoration: 'none', }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link> 
                
                </Grid>
            </Box>

            <TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
            <TableRow >
                <TableCell sx={{ fontWeight: 600 }} align="center">nom_fournisseur</TableCell>

                <TableCell align="center" sx={{ fontWeight: 600 }}>Nom_Produits</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>Description</TableCell>

             
                <TableCell align="center" sx={{ fontWeight: 600 }}>Action</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {produits.map((produit) => (
              <TableRow key={produit.c}>
                <TableCell align="center">{produit.fournisseur.nom} </TableCell>
                <TableCell align="center">{produit.nom_produit}</TableCell>
                <TableCell align="center">{produit.description}</TableCell>
                <TableCell align="center">
                  <Link to={'/ModifierProduits/'+produit.id_produit}>
                  {/* onClick={() => handleEdite(id_utilisateur)} */}
                    <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                  </Link>
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(produit.id_produit)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
    </Table>
</TableContainer>

</Appbar>
  )
}
