import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function AjouterProduits() {
  
    const [formValues, setFormValues] = useState({
      id_produit: '',
      nom_produit: '',
      description:'',
        id_fournisseur:''
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
  
 
          const [fournisseurs, setFournisseurs] = useState([]);
          useEffect(() => {
            axios.get("https://serv.construction.yuliagroupe.com/api/fournisseurs").then((res) => {
              console.log(res.data.fournisseurs);
              setFournisseurs(res.data.fournisseurs);
            });
          },[]);
      
          const navigate=useNavigate()
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post('https://serv.construction.yuliagroupe.com/api/produits', formValues)
            .then(response => {
              console.log('produits ajouté avec succès', response.data.data);
              navigate('/Produits');
              })
              .catch(error => {
                console.error('Erreur lors de l\'ajout de produits', error);
              });
          };

 
 
 
 
 
 
    return (
    <Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                PRODUITS
              </Typography>
              <form onSubmit ={handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={6}>
                    <TextField fullWidth label="ID" name="id_produit" value={formValues.id_produit} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="nom_produit" name="nom_produit" value={formValues.nom_produit} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="description" name="description" value={formValues.description} onChange={handleChange}   InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }} 
                      />
                  </Grid>
                  <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='fournisseur'
          onChange={handleChange}
          name='id_fournisseur'
value={formValues.id_fournisseur}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
< option value="">Sélectionnez un produit</option>
          {fournisseurs.map((fournisseur)=>(
            <option key={fournisseur.id_fournisseur} value={fournisseur.id_fournisseur}>{fournisseur.nom}</option>
          ))}
         
        </TextField>
                      </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
