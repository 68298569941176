
import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
export default function AjouterProdStock() {


    const [formValues, setFormValues] = useState({
      id_produit: '',
      id_stock: '',
      quantite:''
      });
  
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };
    

      const navigate = useNavigate();  
      const [stocks, setStocks] = useState([]);
      useEffect(() => {
        axios.get("https://serv.construction.yuliagroupe.com/api/stocks").then((res) => {
          console.log(res.data.stocks);
          setStocks(res.data.stocks);
        });
      },[]);
      const [produits, setProduits] = useState([]);
      useEffect(() => {
        axios.get("https://serv.construction.yuliagroupe.com/api/produits").then((res) => {
          console.log(res.data.data);
          setProduits(res.data.data);
        });
      },[]);
        
      const handleSubmit = (e) => {
      e.preventDefault();
      axios.post('https://serv.construction.yuliagroupe.com/api/associations_produit_stock', formValues)
      .then(response => {
        console.log('tache ajouté avec succès', response.data.data);
        navigate('/AffectProdStock');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout de la AffectMatierStock', error);
        });
    };




    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Matiere premier_Stock
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='nom_produit'
          onChange={handleChange}
          name='id_produit'
value={formValues.id_produit}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
< option value="">Sélectionnez un produit</option>
          {produits.map((produit)=>(
            <option key={produit.id_produit} value={produit.id_produit}>{produit.nom_produit}</option>
          ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          name='id_stock'
value={formValues.id_stock}
label='nom_stock'
onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }}   >
  < option value="">Sélectionnez un stocck</option>
          {stocks.map((stock)=>(
            <option key={stock.id_stock} value={stock.id_stock}>{stock.id_stock}</option>
          ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                    <TextField label='Quantite' type='number' fullWidth  name="quantite" value={formValues.quantite} onChange={handleChange}    InputProps={{
                        style: {
                          borderRadius: "15px",
                          backgroundColor: 'rgba(255, 255, 255, 95%)',
                          
                        },
                      }}/>
                      
                  </Grid> 
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Confirmer
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
 
                  </Appbar>
  )
}
