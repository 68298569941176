import React from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
export default function ModifierProjet() {
    const [formValues, setFormValues] = useState({
      id_projet: '',
      nom_projet: '',
      date_debut:'',
      date_fin: '',
      id_entreprise: 1
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
          const navigate = useNavigate();
  const {id} = useParams()
  useEffect(()=>{
    handleEdit()
   },[])
   
const handleEdit = (id_projet) => {
  axios.get(`https://serv.construction.yuliagroupe.com/api/projets/${id}`)
    .then(response => {
      console.log(response.data.data)
      const userData = response.data.data; 
      setFormValues({
        id_projet: userData.id_projet,
        nom_projet: userData.nom_projet,
        date_debut: userData.date_debut,
        date_fin: userData.date_fin,
        id_entreprise:1
      
      });
    })
    .catch(error => {
      console.error('Erreur lors de la récupération des données de Projet', error);
    });
};

 
 
const handleEditSubmit = (e) => {
  e.preventDefault();
  axios.put(`https://serv.construction.yuliagroupe.com/api/projets/${id}`,formValues)
    .then(response => {
      console.log('projet modifié avec succès');
      navigate('/projet'); // Utilisez history.push au lieu de navigate
    })
    .catch(error => {
      console.error('Erreur lors de la modification de lprojet', error);
    });
};



    return (
<Appbar>
    <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              alignItems:'center'
            }}
          >
      <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
              <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                Modifier Projet
              </Typography>
              <form onSubmit ={handleEditSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={6}>
                    <TextField fullWidth label="ID_projet" name="id_projet" value={formValues.id_projet} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth label="Nom_projet" name="nom_projet" value={formValues.nom_projet} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }} />
                  </Grid>
                
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth label="date_debut" name="date_debut" value={formValues.date_debut} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}/>
                      
                  </Grid>
                  <Grid item xs={6}>
                    <TextField type='date' fullWidth label="date_fin" name="date_fin" value={formValues.date_fin} onChange={handleChange}   sx={{backgroundColor:'white'}}
          InputLabelProps={{
            style: {
              color: 'black' // Couleur du texte du label en noir
            }
          }}
                      />
                      
                  </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Modifier
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
