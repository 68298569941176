import React, { useEffect } from 'react'
import Appbar from '../Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function ModifierChantier() {
  const navigate = useNavigate()
  const {id} = useParams()
    const [formValues, setFormValues] = useState({
      nom_chantier: '',
      adresse: '',
      Lat: '',
      Date_debut:'',
      Date_fin:'',
      budjet:''       
      });
      useEffect(()=>{
        axios.get("https://serv.construction.yuliagroupe.com/api/chantiers/"+id)
        .then((res)=>{
          setFormValues({
            nom_chantier:res.data.data.nom_chantier,
            adresse:res.data.data.adresse,
            Lat:res.data.data.Lat,
            Date_debut:res.data.data.Date_debut,
            Date_fin:res.data.data.Date_fin,
            budjet:res.data.data.budjet,
          })
        })
      },[])
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
        
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.put("https://serv.construction.yuliagroupe.com/api/chantiers/"+id,formValues)
            .then((res)=>{
              navigate("/chantier")
            })
          
          }
           
 
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '1px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 'bold'}}>
                    Modifier Chantier
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        
                      <Grid item xs={6}>
                        <TextField fullWidth label="nom_chantier" name="nom_chantier" value={formValues.nom_chantier} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Adress" name="adresse" value={formValues.adresse} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="Lat" name="Lat" value={formValues.Lat} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}/>
        
                      </Grid>
                      <Grid item xs={6}>
                        <TextField type='date' fullWidth  name="Date_debut" value={formValues.Date_debut} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}/>
                          
                      </Grid>
                      <Grid item xs={6}>
                        <TextField type='date' fullWidth  name="Date_fin" value={formValues.Date_fin} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}/>
                          
                      </Grid>
    
    
                      <Grid item xs={6}>
                        <TextField fullWidth label="Budjet" name="budjet" value={formValues.budjet} onChange={handleChange}    InputProps={{
                            style: {
                              borderRadius: "5px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }}
                         
                         />
                          
                      </Grid>
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Modifier
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
