import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import axios from 'axios';
import { useState } from 'react';
function Chantier() {
 
 const[chantiers,setChantiers]=useState([])
    const [openCon, setOpenCon] = React.useState(false);
    const navigate = useNavigate()
    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
    useEffect(() => {
      const isloged = JSON.parse(localStorage.getItem("isloged"))
      if(!isloged){
        navigate("/")
      }else{
axios.get("https://serv.construction.yuliagroupe.com/api/chantiers").then((res) => {
  setChantiers(res.data.data)
});
}

},[]);
 
const handleDelete = (id_chantier) => {
  axios.delete(`https://serv.construction.yuliagroupe.com/api/chantiers/${id_chantier}`)
    .then(response => {
      console.log('TypeRisque supprimé avec succès');
      
      const updatedUsers = chantiers.filter(Chantier => Chantier.id_chantier !== id_chantier);
      setChantiers(updatedUsers);
      handleCloseCon();
    })
    .catch(error => {
      console.error('Erreur lors de la suppression de l\'types-risque', error);
      });
    }; 











    return (
    <Appbar>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
                <Grid container spacing={2} alignItems="center">
                <Link to="/AjouterChantier" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>

    </Link>   
                  <Link to="/EmpChant" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30%',
          alignSelf: 'flex-end',
          marginLeft: '650px',
      }}>
        <ChromeReaderModeIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Affecter_employe_chantier 
      </Button>
    </Link>    
                </Grid>
                
            </Box>

            <TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                        <TableRow>
                            <TableCell align="center">Nom_Echantier</TableCell>
                            <TableCell align="center">Adresse</TableCell>
                            <TableCell align="center">Lat</TableCell>
                            <TableCell align="center">Date Debut</TableCell>
                            <TableCell align="center">Date Fin</TableCell>
                            <TableCell align="center">Budjet</TableCell>

                            <TableCell align="center" sx={{color:'#001F3F'}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {chantiers.map((chantier)=>(
          <TableRow key={chantier.id_chantier}>
          <TableCell align="center">{chantier.nom_chantier}</TableCell>
          <TableCell align="center">{chantier.adresse}</TableCell>
          <TableCell align="center">{chantier.Lat}</TableCell>
          <TableCell align="center">{chantier.Date_debut}</TableCell>
          <TableCell align="center">{chantier.Date_fin}</TableCell>
          <TableCell align="center">{chantier.budjet}</TableCell>

          <TableCell align="center">
            <Link to={"/ModifierChantier/"+chantier.id_chantier}>
              <IconButton>
                <EditIcon sx={{ color: '#d35400' }} />
              </IconButton>
            </Link>
            <React.Fragment>
              <IconButton variant="outlined" onClick={handleClickOpenCon}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
              <Dialog
                open={openCon}
                onClose={handleCloseCon}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent sx={{ backgroundColor: '#d35400' }}>
                  <DialogContentText id="alert-dialog-description">
                    Vous avez sure de supprimer 
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#d35400' }}>
                  <Button onClick={handleCloseCon}>Non</Button>
                  <Button onClick={()=>handleDelete(chantier.id_chantieré)}>Oui</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </TableCell>
        </TableRow>
        ))}
        

    </TableBody>
                </Table>
            </TableContainer>
        </Appbar>


  )
}

export default Chantier
