import React, { useEffect, useState } from 'react';
import Appbar from '../Appbar';
import { TextField, Button, Typography, Paper, Box, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';

export default function ModifierTypeConstrc() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValues, setFormValues] = useState({
    Nom_typeconst: ''
  });

  useEffect(() => {
    axios.get(`https://serv.construction.yuliagroupe.com/api/typesconstruction/${id}`)
      .then((res) => {
        setFormValues({
          Nom_typeconst: res.data.data.Nom_typeconst
        });
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`https://serv.construction.yuliagroupe.com/api/typesconstruction/${id}`, formValues)
      .then((res) => {
        navigate("/TypeConstruction");
      });
  };

  return (
    <Appbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: '80px'
        }}
      >
        <Paper elevation={3} sx={{ padding: '20px', width: '600px', borderRadius: '5px', backgroundColor: '#C5C5C5' }}>
          <Typography variant="h5" align="center" sx={{ color: '#001f3f', fontWeight: 600 }}>
            Modifier Type_Construction
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ marginTop: '20px', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nom"
                  name="Nom_typeconst"
                  value={formValues.Nom_typeconst}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: 'rgba(255, 255, 255, 95%)'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#d35400',
                  color: '#001f3f',
                  '&:hover': {
                    backgroundColor: '#d35400'
                  }
                }}
              >
                Modifier
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Appbar>
  );
}
