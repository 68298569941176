import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import {  IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export default function Matierepremier() {
    const [openCon, setOpenCon] = React.useState(false);
    const navigate = useNavigate()
    const handleClickOpenCon = () => {
        setOpenCon(true);
      };
      
      const handleCloseCon = () => {
        
        setOpenCon(false);
      };
    
    
      const[matierepremiers,setMatierepremiers]=React.useState([])
  
  
      const handleDelete = (id_matiere_premiere) => {
          axios.delete(`https://serv.construction.yuliagroupe.com/api/lesmatierspremiers/${id_matiere_premiere}`)
            .then(response => {
              console.log('matierepremier supprimé avec succès');
              getdata()
              handleCloseCon();
            })
            .catch(error => {
              console.error('Erreur lors de la suppression de l\'Matierepremier', error);
              });
            }; 
  
  
  
  const getdata = () => {
    axios.get("https://serv.construction.yuliagroupe.com/api/lesmatierspremiers").then((res) => {
      console.log(res.data.data)  
      setMatierepremiers(res.data.data)
  })
}
        useEffect(() => {
          const isloged = JSON.parse(localStorage.getItem("isloged"))
          if(!isloged){
            navigate("/")
          }else{
            getdata()
        };
      
    
    },[]);





    
  return (
    <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'75px' }}>
                   <Grid container spacing={2} alignItems="center">
                   <Link to="/AjouterMatierePremier" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '0px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
              
                   </Grid>
               </Box>
   
               <TableContainer  style={{width:'1000px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
               <TableRow >
                   <TableCell align="center" sx={{ fontWeight: 600 }}>Nom_MatierePremier</TableCell>
                   <TableCell align="center" sx={{ fontWeight: 600 }}>Description</TableCell>
   
                
                   <TableCell align="center" sx={{ fontWeight: 600 }}>Action</TableCell>
               </TableRow>
           </TableHead>
           <TableBody>
                    {matierepremiers.map((Matierepremier)=>(
          <TableRow key={Matierepremier.id_matiere_premiere}>
          <TableCell align="center">{Matierepremier.Nom_Matière_Première}</TableCell>
          <TableCell align="center">{Matierepremier.Description}</TableCell>

    

          <TableCell align="center">
            <Link to={"/ModifierMatierePremier/"+Matierepremier.id_matiere_premiere}>
              <IconButton>
                <EditIcon sx={{ color: '#d35400' }} />
              </IconButton>
            </Link>
            <React.Fragment>
              <IconButton variant="outlined" onClick={handleClickOpenCon}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
              <Dialog
                open={openCon}
                onClose={handleCloseCon}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent sx={{ backgroundColor: '#d35400' }}>
                  <DialogContentText id="alert-dialog-description">
                    Vous avez sure de supprimer 
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#d35400' }}>
                  <Button onClick={handleCloseCon}>Non</Button>
                  <Button onClick={()=>handleDelete(Matierepremier.id_matiere_premiere)}>Oui</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </TableCell>
        </TableRow>
        ))}
        

    </TableBody>
       </Table>
   </TableContainer>
   
   </Appbar>
  )
}
