import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

function Entreprise() {
 const[entreprises,setEntreprises]=useState([])
 
 const navigate = useNavigate()
 
    useEffect(() => {
      const isloged = JSON.parse(localStorage.getItem("isloged"))
      if(!isloged){
        navigate("/")
      }else{
        axios.get("https://serv.construction.yuliagroupe.com/api/entreprises").then((res) => {
          console.log(res.data.entreprises);
          setEntreprises(res.data.entreprises);
        });
      }

    },[]);
  
 
 
      const [openCon, setOpenCon] = React.useState(false);
      const handleClickOpenCon = () => {
        setOpenCon(true);
      };
      
      const handleCloseCon = () => {
      
        setOpenCon(false);
      };
      
  
 
 
 
      const handleDelete = (id_entreprise) => {
        axios.delete(`https://serv.construction.yuliagroupe.com/api/entreprises/${id_entreprise}`)
          .then(response => {
            console.log('entreprises supprimé avec succès');
            // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
            const updatedUsers = entreprises.filter(entreprise => entreprise.id_entreprise !== id_entreprise);
            setEntreprises(updatedUsers);
            handleCloseCon();
          })
          .catch(error => {
            console.error('Erreur lors de la suppression de entreprises', error);
          });
      };
        
 
 
      
 
    return (
    <Appbar>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
        <Grid container spacing={2} alignItems="center">
        <Link to="/AjouterEntreprise" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
             
        </Grid>
    </Box>
    <TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                    <TableRow>
                        <TableCell align="center">ID_Entreprise</TableCell>
                        <TableCell align="center">Login</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
            {entreprises.map((entreprise) => (
              <TableRow key={entreprise.id_entreprise}>
                <TableCell align="center">{entreprise.id_entreprise}</TableCell>
                <TableCell align="center">{entreprise.login}</TableCell>
                <TableCell align="center">
                  <Link to={'/modifierEntreprise/'+entreprise.id_entreprise}>
                    <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                  </Link>
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(entreprise.id_entreprise)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
            </Table>
        </TableContainer>
</Appbar>
  )
}

export default Entreprise
