import * as React from 'react';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import logo from './img/logo.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import './App.css'
// import Typography from '@mui/material/Typography';

function Login() {
  const [email, setEmail] = useState('');
  const [motdepass, setMotdepass] = useState('');
  const [error, setError] = useState('');
const navigate=useNavigate(
  React.useEffect(()=>{
    const isloged = JSON.parse(localStorage.getItem("isloged"))
    if(isloged){
      navigate("/login")
    }
  })
)
  const handleSubmit = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire
    const data = {
        'email': email,
        'mot_de_pass': motdepass
    };

    // Envoi des données au serveur
    axios.post('https://serv.construction.yuliagroupe.com/api/login', data)
    .then((res) => {
            console.log(res.data.utilisateur);
            localStorage.setItem("userdata",JSON.stringify(res.data.utilisateur))
            localStorage.setItem("isloged",true)
            // setFonction(res.data.function)
            if(res.data.utilisateur.id_role === '1'){
              navigate('/dashboard');
            } else 
           navigate('/tache');
          
        })
        .catch((err) => {
            console.log(err.response.data);
            setError(err.response.data.message);

        });
};

return (
    <>
      <Box
      class = 'form'
        className='Form'
        sx={{
          backgroundImage: "url('images/back.jpg')",
          display:"flex",
          justifyContent:"center"
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <img src={logo} alt="Logo" style={{width:'380px',marginBottom:'100px'}} />
          </div>
          <div>
            <TextField
              margin="normal"
              required
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}

              InputProps={{
                style: {
                  borderRadius: "25px" ,
                  backgroundColor:'rgba(255, 255, 255, 95%)'
                },
              }}
              style={{ width: '500px',color: '#001f3f'}}
            />
          </div>
          <div>
            <TextField
              margin="normal"
              required
              fullWidth
              name="motdepass"
              label="Password"
              value={motdepass}
              onChange={(e) => setMotdepass(e.target.value)}

              type="password"
              id="password"
              autoComplete="current-password"
            
              InputProps={{
                style: {
                  borderRadius: "25px",
                  backgroundColor:'rgba(255, 255, 255, 95%)'
                },
              }}
              style={{ width: '500px',color:'#001f3f'}}
            />
          </div>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            style={{ marginRight: '300px',color:'#001f3f' }}
          />
          <br />
          <br />
          <Button
            style={{ backgroundColor: 'rgba(255, 255, 255, 95%)', width: '2 00px', borderRadius: 25, color:'#001f3f',marginBottom:'40px' }}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Login;

