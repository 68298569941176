
import React from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function ModifierTacheUser() {
  const [formValues, setFormValues] = useState({
        id_tache: '',
        date_debut:'',
        date_fin:'',
        assiniera:'',
        assinierpar:'',
        validerpar:'',
        nom_tache:''
      });




        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };

          const [taches, setTaches] = useState([]);

          useEffect(() => {
            axios.get("https://serv.construction.yuliagroupe.com/api/taches").then((res) => {
              console.log(res.data.data);
              setTaches(res.data.data)
            });
          },[]);
            
          const [users, setUsers] = useState([]);
          useEffect(() => {
            axios.get("https://serv.construction.yuliagroupe.com/api/utilisateurs").then((res) => {
              console.log(res.data.utilisateurs);
              setUsers(res.data.utilisateurs);
            });
          },[]);
 
          const navigate=useNavigate()
          const handleSubmit = (e) => {
            e.preventDefault();
            axios.post("https://serv.construction.yuliagroupe.com/api/validerpar",{id_utilisateur:formValues.validerpar , id_tache:formValues.id_tache})
            .then((res)=>{
              console.log(res.data)
            })
            axios.post("https://serv.construction.yuliagroupe.com/api/assenieras",{id_utilisateur:formValues.assiniera , id_tache:formValues.id_tache})
            .then((res)=>{
              console.log(res.data)
            })
            axios.post("https://serv.construction.yuliagroupe.com/api/assenierspar",{id_utilisateur:formValues.assinierpar , id_tache:formValues.id_tache})
            .then((res)=>{
              console.log(res.data)
              navigate('/AffectTachUser')
            })
          
          }
    
    
  
  
  
  
  
    return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '180vh', borderRadius: '5px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Ajouter tache a un Utilisateur
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={6}>
                    <TextField   
                    
                    id="outlined-select-currency-native"
                    aria-label='Nom_Tache a'
                    onChange={handleChange}

                    name='id_tache'
          value={formValues.id_tache}
                    select
                    label="Nom_Tache "
          
                    SelectProps={{
                      native: true,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                        backgroundColor: 'rgba(255, 255, 255, 95%)',
                        width:'521px'
                      },
                    }} 
                  >
          < option value="">Sélectionnez un tache</option>
         {taches.map((tache)=>(
           <option key={tache.id_tache} value={tache.id_tache}>{tache.nom_tache}</option>
         ))}
            </TextField>
                  </Grid>
                      <Grid item xs={6}>
                      <TextField
                    
          id="outlined-select-currency-native"
          aria-label='assiné a'
          onChange={handleChange}

          name='assiniera'
value={formValues.assiniera}
          select
          label="Assiné a"

          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }} 
        >
 < option value="">Sélectionnez un user</option>
         {users.map((user)=>(
           <option key={user.id_utilisateur} value={user.id_utilisateur}>{user.nom_utilisateur}{user.prenom_utilisateur}</option>
         ))}
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label="Assiné par"
          onChange={handleChange}
          name='assinierpar'
          value={formValues.assinierpar}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }}   >
      < option value="">Sélectionnez un user</option>
         {users.map((user)=>(
           <option key={user.id_utilisateur} value={user.id_utilisateur}>{user.nom_utilisateur}{user.prenom_utilisateur}</option>
         ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label="Validée par"
          onChange={handleChange}
          name='validerpar'
          value={formValues.validerpar}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'521px'
            },
          }}   >
     < option value="">Sélectionnez un user</option>
     {users.map((user)=>(
           <option key={user.id_utilisateur} value={user.id_utilisateur}>{user.nom_utilisateur}{user.prenom_utilisateur}</option>
         ))}
        </TextField>
                      </Grid>
                  


                 
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Confirmer
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
 
                  </Appbar>
  )
}
