import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Link } from 'react-router-dom';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Role() {
  const styleFont = { fontWeight: 600 };
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [openCon, setOpenCon] = useState(false);

  const handleClickOpenCon = () => {
    setOpenCon(true);
  };

  const handleCloseCon = () => {
    setOpenCon(false);
  };

  useEffect(() => {
    const isloged = JSON.parse(localStorage.getItem("isloged"));
    if (!isloged) {
      navigate("/");
    } else {
      axios.get("https://serv.construction.yuliagroupe.com/api/roles").then((res) => {
        console.log(res.data.roles);
        setRoles(res.data.roles);
      });
    }
  }, []);

  const handleDelete = (id_role) => {
    axios.delete(`https://serv.construction.yuliagroupe.com/api/roles/${id_role}`)
      .then(response => {
        console.log('Role supprimé avec succès');
        const updatedRoles = roles.filter(role => role.id_role !== id_role);
        setRoles(updatedRoles);
        handleCloseCon();
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du rôle', error);
      });
  };

  return (
    <Appbar>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginLeft: '50px' }}>
        <Grid container spacing={2} alignItems="center">
          <Link to="/AjouterRole" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{
              mb: 2,
              bgcolor: '#d35400',
              borderRadius: '9px',
              fontFamily: 'Roboto',
              '&:hover': { bgcolor: '#d35400' },
              color: 'white',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              alignSelf: 'flex-end',
              marginLeft: '20px',
            }}>
              <AddCircleIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
              Ajouter Role
            </Button>
          </Link>

          <Grid item sx={{ marginLeft: '500px', marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
          <Link to="/AffePrevilageRole" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '40px',
      }}>
        <ChromeReaderModeIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Affecter previlage role 
      </Button>
    </Link>   
 
</Grid>
            
        </Grid>
      </Box>

      <TableContainer style={{ width: '925px', marginLeft: '50px', borderRadius: '9px', border: '1px solid ', marginTop: '20px' }}>
        <Table sx={{ minWidth: 650, border: '0.1px solid #ddd' }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#e8e8e8' }}>
            <TableRow>
              <TableCell align="center" sx={styleFont}>Nom_Role</TableCell>
              <TableCell align="center" sx={styleFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow key={role.id_role}>
                <TableCell align="center">{role.nom_role}</TableCell>
                <TableCell align="center">
                  <Link to={'/ModifierRole/' + role.id_role}>
                    <IconButton><EditIcon sx={{ color: '#d35400' }} /></IconButton>
                  </Link>
                  <React.Fragment>
                    <IconButton variant="outlined" onClick={handleClickOpenCon}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer ce rôle ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(role.id_role)}>Oui</Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Appbar>
  );
}