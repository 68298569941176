import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import {  IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import { Menu,MenuItem } from '@mui/material';
import { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import {useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
function Stock() {
  const navigate = useNavigate();
  
  const [stocks, setStocks] = useState([]);

    const [openCon, setOpenCon] = React.useState(false);

    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);   /// pour menu
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    useEffect(() => {
      const isloged = JSON.parse(localStorage.getItem("isloged"))
      if(!isloged){
        navigate("/")
      }else{
      axios.get("https://serv.construction.yuliagroupe.com/api/stocks").then((res) => {
        console.log(res.data.stocks);
        setStocks(res.data.stocks);
      });
    }
    
    },[]);
      
  
const handleDelete = (id_stock) => {
  axios.delete(`https://serv.construction.yuliagroupe.com/api/stocks/${id_stock}`)
    .then(response => {
      console.log('stocks supprimé avec succès');
      // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
      const updatedUsers = stocks.filter(stock => stock.id_stock !== id_stock);
      setStocks(updatedUsers);
      handleCloseCon();
    })
    .catch(error => {
      console.error('Erreur lors de la suppression de stocks', error);
    });
};
  
  
    return (
    <Appbar>
 <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px',marginLeft:"60px" }}>
                <Grid container spacing={2} alignItems="center">
              
                <Link to="/AjouterStock" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
    <Grid item sx={{ marginLeft: '710px', marginBottom: '20px' }}>
  <Toolbar>
    <IconButton
      edge="end"
      aria-label="menu"
      aria-controls="settings-menu"
      aria-haspopup="true"
      onClick={handleClick}
      sx={{
        mb: 2,
        bgcolor: '#d35400',
        borderRadius: '9px',
        fontFamily: 'Roboto',
        '&:hover': { bgcolor: '#d35400' },
        color: 'white',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <MenuIcon sx={{ color: '#fff' }} />
      <Typography variant="h6" sx={{ color: '#ffff', marginLeft: '5px',fontFamily:"Roboto" }}>Affectation</Typography>
    </IconButton>
    <Menu
      id="settings-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        '& .MuiMenuItem-root': {
          fontFamily: 'Roboto',
          textTransform: 'none',
        },
      }}
    >
      <MenuItem onClick={() => { navigate('/AffectMatierStock'); handleClose(); }}>Matier_a_stock</MenuItem>
      <MenuItem onClick={() => { navigate('/AffectProdStock'); handleClose(); }}>Produit_a_stock</MenuItem>
    </Menu>
  </Toolbar>
</Grid>








                 
                </Grid>
            </Box>
            
            <TableContainer  style={{width:'980px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
            <TableRow >
                <TableCell align="center" sx={{ fontWeight: 600 }}>Quantiteé</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>Action</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {stocks.map((stock) => (
              <TableRow key={stock.id_stock}>
                <TableCell align="center">{stock.quantite}</TableCell>
              
                <TableCell align="center">
                  <Link to={'/ModifierStock/'+stock.id_stock}>
                  {/* onClick={() => handleEdite(id_utilisateur)} */}
                    <IconButton><EditIcon sx={{color:'#d35400'}} /></IconButton>
                  </Link>
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(stock.id_stock)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
    </Table>
</TableContainer>

</Appbar>
  )
}
export default Stock
