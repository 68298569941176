import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Appbar from './Appbar';
import Box from '@mui/material/Box';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
export default function TimeLine() {

  
    const [openCon, setOpenCon] = React.useState(false);
    const navigate = useNavigate()
    const handleClickOpenCon = () => {
      setOpenCon(true);
    };
    
    const handleCloseCon = () => {
    
      setOpenCon(false);
    };
  
const [times,setTimes]=useState([])
useEffect(() => {
  const isloged = JSON.parse(localStorage.getItem("isloged"))
  if(!isloged){
    navigate("/")
  }else{
      axios.get("https://serv.construction.yuliagroupe.com/api/timelines").then((res) => {
        console.log(res.data.data);
        setTimes(res.data.data);
      });
    }
    
    },[]);
    const handleDelete = (id_Timeline) => {
      axios.delete(`https://serv.construction.yuliagroupe.com/api/timelines/${id_Timeline}`)
        .then(response => {
          console.log('timelines supprimé avec succès');
          // Mettre à jour les données dans le tableau en excluant l'utilisateur supprimé
          const updatedUsers = times.filter(time => time.id_Timeline !== id_Timeline);
          setTimes(updatedUsers);
          handleCloseCon();
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de l\'utilisateur', error);
        });
    };
    return (
    <Appbar>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'30px', marginLeft:'50px' }}>
        <Grid container spacing={2} alignItems="center">
        <Link to="/AjouterTimeline" style={{ textDecoration: 'none' }}>
      <Button variant="contained" sx={{
          mb: 2,
          bgcolor: '#d35400',
          borderRadius: '9px',
          fontFamily: 'Roboto',
          '&:hover': { bgcolor: '#d35400' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          alignSelf: 'flex-end',
          marginLeft: '20px',
      }}>
        <AddIcon sx={{ mr: 1, fontWeight: 'Roboto', fontSize: '15px' }} />
        Ajouter Nouveau
      </Button>
    </Link>   
        </Grid>
    </Box>

    <TableContainer  style={{width:'1070px',marginLeft:'50px',borderRadius:'9px',border:'1px solid ',marginTop:'20px'}} >
                <Table sx={{ minWidth: 650, border: '0.1px solid #ddd', }} aria-label="simple table">
                <TableHead sx={{backgroundColor: '#e8e8e8'}}>
                <TableRow>
                    <TableCell align="center">Nom_Stage</TableCell>
                    <TableCell align="center">nom_Projet </TableCell>
                    <TableCell align="center">Date_Destimation_finition</TableCell>
                    <TableCell align="center">Date Debut</TableCell>
                    <TableCell align="center">Date Fin</TableCell>

                    <TableCell align="center" sx={{color:'#001F3F'}}>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {times.map((time) => (
              <TableRow key={time.id_Timeline}>
                <TableCell align="center">{time.stage.Stage_Name}</TableCell>
                <TableCell align="center">{time.projet.nom_projet}</TableCell>
                <TableCell align="center">{time.Date_debut}</TableCell>
                <TableCell align="center">{time.date_estimation}</TableCell>
                <TableCell align="center">{time.Date_fin}</TableCell>

                <TableCell align="center">
                  <React.Fragment>
                  <IconButton variant="outlined" onClick={handleClickOpenCon} >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(time.id_Timeline)}> oui </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </TableContainer>
</Appbar>
  )
}
