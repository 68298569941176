import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Appbar from './Appbar';
import { MapContainer, TileLayer } from 'react-leaflet'
import {Chart as ChartJS} from 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2';
import { LinearProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router';
import {Stack} from '@mui/system'
function Dashboard() {
  const center = [51.505, -0.09];
  const navigate = useNavigate()
  useEffect(()=>{
          const isloged = JSON.parse(localStorage.getItem("isloged"))
          if(!isloged){
            navigate("/")
          }
          const user = JSON.parse(localStorage.getItem("userdata"))

  },[])
  
  return (
    
    <Appbar>
    <Box height={30}  >
  <Box sx={{ flexGrow: 1 }}>
           <Grid container spacing={4} backgroundColor='#E5E5E5'  >
        <Grid item xs={12} sm={6} md={4} >

        <Card sx={{ maxWidth: 345, flexGrow: 1, height: '100%'}}>
      <CardContent sx={{ flexGrow: 1, position: 'relative' , height:"100%"}}>
        <Typography gutterBottom variant="h5" component="div">
          Last Projects
        </Typography>
        <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0  }}>
          <MapContainer center={center} zoom={13} style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </MapContainer>
        </Box>
      </CardContent>
    </Card>


    
    </Grid>
    <Grid item xs={12} sm={6} md={4} >

    <Card sx={{ maxWidth: 345, flexGrow: 1, height: '100%' }}>
   
   <CardContent>
     <Typography gutterBottom variant="h5" component="div">
Weather     </Typography>
    
   </CardContent>
 </Card>
 </Grid>
 <Grid item xs={12} sm={6} md={4} >

 <Card sx={{ maxWidth: 345, flexGrow: 1, height: '100%' }}>
   
   <CardContent>
     <Typography gutterBottom variant="h5" component="div">
       Tasck Overview
     </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }} >
    <Doughnut
  data={{
    labels: ['A', 'B', 'C'],
    datasets: [
      {
        label: 'Revenue',
        data: [200, 300, 62],
        backgroundColor: ['#F1C40F', '#C0392B', '#D35400']
      },
    ]
  }}
  options={{
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          return context.dataIndex === 2 ? '62' : '200';
          {console.log(value)}

        },
        color: '#fff',
        font: {
          size: 20
        },
        anchor: 'center',
        align: 'center'
      }
    }
  }}

/>





    </Box>
   </CardContent>
 
 </Card>
 </Grid>



<Grid item  md={12} >

<Card sx={{height:'70vh',backgroundColor:'#E5E5E5'}}>
   
   <CardContent>
     <Typography gutterBottom variant="h5" sx={{textAlign:'left'}}>
       Projects status
     </Typography>
        <Box sx={{display:"flex" , justifyContent:"space-around" , columnGap:"90px"}}>
      {/* start */}
    <Grid item md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
<Typography gutterBottom variant="h6"  sx={{textAlign:'left'}}>
  Budjet
</Typography>
{/* start first grid */}

<Box sx={{display:'flex', justifyContent:'space-between'}}>
<Typography gutterBottom variant="h7" sx={{textAlign:'left'}} >
  Totale Budjet
</Typography>
<Typography  gutterBottom variant="h7" >£ 1000.0</Typography>
</Box>

<Box sx={{display:'flex', justifyContent:'space-between'}}>

<Typography gutterBottom variant="h7" sx={{textAlign:'left'}}  >
  Budjet Spend
</Typography>
<Typography  gutterBottom variant="h7" >£ 2000.0</Typography>

</Box>

    </Grid>
    
    {/* start sec gride */}
    <Grid item  md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
<Typography gutterBottom variant="h6"  sx={{textAlign:'left'}}>
Time</Typography>
<Box sx={{display:'flex', justifyContent:'space-between'}}>
<Typography gutterBottom variant="h7" sx={{textAlign:'left'}} >
  Deade line
</Typography>
<Typography  gutterBottom variant="h7" >£ 1000.0</Typography>
</Box>

<Box sx={{display:'flex', justifyContent:'space-between'}}>

<Typography gutterBottom variant="h7" sx={{textAlign:'left'}}  >
Time Elapsed</Typography>
<Typography  gutterBottom variant="h7" >£ 2000.0</Typography>

</Box>

    </Grid>
    {/* end sec grid */}
    {/* end gride */}




    {/* star3 */}
    
    <Grid item  md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
<Typography gutterBottom variant="h6"  sx={{textAlign:'left'}}>
Quality</Typography>
<Box sx={{display:'flex', justifyContent:'space-between'}}>
<Typography gutterBottom variant="h7" sx={{textAlign:'left'}} >
Open Rfi</Typography>
<Chip sx={{width:'30px', height:'20px', borderRadius: '30',backgroundColor:'#C0392B'}}  />
</Box>

<Box sx={{display:'flex', justifyContent:'space-between'}}>

<Typography gutterBottom variant="h7" sx={{textAlign:'left'}}  >
Rfi Overdue</Typography>
<Chip sx={{width:'30px', height:'20px', borderRadius: '30',backgroundColor:'#C0392B'}}  />
</Box>

    </Grid>
   
    </Box>
    <Box sx={{ display: 'flex', marginTop: '100px', gap: '50px' }}>
  <LinearProgress
    color="warning"
    variant="determinate"
    value={60}
    sx={{
      width: '350px',
      backgroundImage:
        'linear-gradient(to right, #8b4513 25%, #c0392b 25%, #c0392b 50%, #f1c40f 50%, #f1c40f 75%, #f1c40f 75%, #808080 100%)',
      borderRadius: '5px',
    }}
  />
  <LinearProgress
    color="warning"
    variant="determinate"
    value={60}
    sx={{
      width: '350px',
      backgroundImage:
        'linear-gradient(to right, #8b4513 25%, #c0392b 25%, #c0392b 50%, #f1c40f 50%, #f1c40f 75%, #f1c40f 75%, #808080 100%)',
      borderRadius: '5px',
    }}
  />
</Box>
   </CardContent>
 </Card>



</Grid>

          </Grid>
    </Box>
    

    </Box>
    </Appbar>

  
  )
}

export default Dashboard
