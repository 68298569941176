import React from 'react'
import Appbar from './Appbar'
import { TextField, Button,  Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
export default function AjouterRolPermission() {
  
  const [formValues, setFormValues] = useState({
    id_role: '',
    id_privilege: '',

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const navigate = useNavigate();  
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/roles").then((res) => {
      console.log(res.data.roles);
      setRoles(res.data.roles);
    });
  },[]);
  const [previlages, setPrevilages] = useState([]);
  useEffect(() => {
    axios.get("https://serv.construction.yuliagroupe.com/api/privileges").then((res) => {
      console.log(res.data.privileges);
      setPrevilages(res.data.privileges);
    });
  },[]);
    
  const handleSubmit = (e) => {

    e.preventDefault();
    axios.post('https://serv.construction.yuliagroupe.com/api/association-privileges-roles', formValues)
    .then(response => {
      console.log('tache ajouté avec succès', response.data.associations);
      navigate('/AffePrevilageRole');
      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout de la AffectMatierStock', error);
      });
  };
  
  return (
    <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'50px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
               affecter Role Permission
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={6}>
                      <TextField
                    
          id="outlined-select-currency-native"
          aria-label='assiné a'
          onChange={handleChange}
          name='id_role'
value={formValues.id_role}
          select
          label=" Role"

          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
 < option value="">Sélectionnez un role</option>
          {roles.map((role)=>(
            <option key={role.id_role} value={role.id_role}>{role.nom_role}</option>
          ))}
         
        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
                    
          id="outlined-select-currency-native"
          aria-label='assiné a'
          onChange={handleChange}
          name='id_privilege'
          value={formValues.id_privilege}
          select
          label=" Permission"

          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
 < option value="">Sélectionnez un permission</option>
          {previlages.map((previlage)=>(
            <option key={previlage.id_priveleges} value={previlage.id_priveleges}>{previlage.permission}</option>
          ))}
        </TextField>
                      </Grid>
                      </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
        marginTop: '20px',
        color: '#001f3f',
        '&:hover': {
          backgroundColor: '#d35400', // Garder la même couleur lors du survol
        },}}>
                    Ajouter
                  </Button>
                </Box>
                  </form>
    
    
    
    
                  </Paper>
                  </Box>
        </Appbar>
  )
}
