import React, { useEffect } from 'react'
import Appbar from './Appbar'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Paper, Box } from '@mui/material';
import  { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
export default function AjouterMateriel() {

  const navigate = useNavigate()
 const [formValues, setFormValues] = useState({
  id_materiel: '',
  nom_materiel: '',
  quantité_materiel:'',
  Nom_fournisseur:''
       
       
      });
 
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
          };
 

    const handleSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();
      axios.post('https://serv.construction.yuliagroupe.com/api/materiels-fournisseurs', formValues)
      .then(response => {
        console.log('materiels-fournisseur ajouté avec succès', response.data.data);
        navigate('/materiel');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout de materiels-fournisseur', error);
        });

  }




  const [fournisseur , setFournisseurs] = useState([])
  useEffect(()=>{
    axios.get("https://serv.construction.yuliagroupe.com/api/fournisseurs")
    .then((res)=>{
      setFournisseurs(res.data.fournisseurs)
    })
  },[])

return (
        <Appbar>
        <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  alignItems:'center'
                }}
              >
          <Paper elevation={3} sx={{ padding: '20px', width: '700px', borderRadius: '15px', backgroundColor: '#C5C5C5',marginTop:'80px' }}>
                  <Typography variant="h5" align="center" sx={{color:'#001f3f',fontWeight: 600}}>
                    Matériel Fournisseur
                  </Typography>
                  <form onSubmit ={handleSubmit}>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={6}>
                        <TextField fullWidth label="ID" name="id_materiel" value={formValues.id_materiel} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField fullWidth label="nom_materiel" name="nom_materiel" value={formValues.nom_materiel} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField type='number' fullWidth label="Quantitée_materiel" name="quantité_materiel" value={formValues.quantité_materiel} onChange={handleChange}   InputProps={{
                            style: {
                              borderRadius: "15px",
                              backgroundColor: 'rgba(255, 255, 255, 95%)',
                              
                            },
                          }} 
                          />
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
          id="outlined-select-currency-native"
          select
          label='nom_fournisseur'
          onChange={handleChange}
          name='Nom_fournisseur'
value={formValues.Nom_fournisseur}
          SelectProps={{
            native: true,
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
              backgroundColor: 'rgba(255, 255, 255, 95%)',
              width:'350px'
            },
          }} 
        >
  <option value="USD">  </option>
  {fournisseur.map((fr)=>(
    <option value={fr.id_fournisseur}>{fr.nom}</option>
  ))}
  
         
        </TextField>
                      </Grid>
                    
                      </Grid>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button type="submit" variant="contained" sx={{ backgroundColor: '#d35400',
            marginTop: '20px',
            color: '#001f3f',
            '&:hover': {
              backgroundColor: '#d35400', // Garder la même couleur lors du survol
            },}}>
                        Ajouter
                      </Button>
                    </Box>
                      </form>
        
        
        
        
                      </Paper>
                      </Box>
            </Appbar>
  )
}
