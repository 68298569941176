import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Appbar from './Appbar';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Pointage() {
  const [pointages, setPointages] = useState([]);
  const navigate = useNavigate();
  const [openCon, setOpenCon] = useState(false);

  const handleClickOpenCon = () => {
    setOpenCon(true);
  };

  const handleCloseCon = () => {
    setOpenCon(false);
  };

  useEffect(() => {
    const isLogged = JSON.parse(localStorage.getItem("isloged"));
    if (!isLogged) {
      navigate("/");
    } else {
      axios.get("https://serv.construction.yuliagroupe.com/api/pointages").then((res) => {
        console.log(res.data.data);
        setPointages(res.data.data);
      });
    }
  }, []);

  const handleDelete = (id_pointage) => {
    axios.delete(`https://serv.construction.yuliagroupe.com/api/pointages/${id_pointage}`)
      .then(response => {
        console.log('Pointage supprimé avec succès');
        const updatedUsers = pointages.filter(pointage => pointage.id_pointage !== id_pointage);
        setPointages(updatedUsers);
        handleCloseCon();
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du pointage', error);
      });
  };

  return (
    <Appbar>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginLeft: '110px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Link to="/AjouterPointage">
              <Button variant="contained" sx={{
                mb: 2,
                bgcolor: '#d35400',
                borderRadius: '9px',
                fontFamily: 'Roboto',
                '&:hover': { bgcolor: '#d35400' },
                color: 'white',
                textTransform: 'none',
              }}>
                <AddCircleIcon sx={{ mr: 1 }} />
                Ajouter Pointage
              </Button>
            </Link>
          </Grid>
        
        </Grid>
      </Box>

      <TableContainer style={{ width: '80%', marginLeft: '100px', marginTop: '10px', borderRadius: '9px', border: '1px solid #ddd' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#e8e8e8' }}>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 600 }}>date_Pointage</TableCell>
              <TableCell align="center" sx={{ fontWeight: 600 }}>chantier</TableCell>
              <TableCell align="center" sx={{ fontWeight: 600}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pointages.map((pointage) => (
              <TableRow key={pointage.id_pointage}>
                <TableCell align="center">{pointage.date_pointage}</TableCell>
                <TableCell align="center">{pointage.chantier.nom_chantier}</TableCell>
                <TableCell align="center">
                  <Link to={'/ModifierPointage/' + pointage.id_pointage}>
                    <IconButton><EditIcon sx={{ color: '#d35400' }} /></IconButton>
                  </Link>
                  <React.Fragment>
                    <IconButton variant="outlined" onClick={handleClickOpenCon}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <Dialog
                      open={openCon}
                      onClose={handleCloseCon}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Êtes-vous sûr de vouloir supprimer ce pointage ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseCon}>Non</Button>
                        <Button onClick={() => handleDelete(pointage.id_pointage)}>Oui</Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Appbar>
  );
}
